/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../../../../app/commonSlice";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const saveBudget = createAsyncThunk(
  "post/saveBudget",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { cmdbId, payload} = props;

      const response = await fetch(
        `${proxy}/api/v1/budgetConfig/saveOrUpdateBudgetConfig/${cmdbId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCountBudget = createAsyncThunk(
  "post/getCountBudget",
  async (props) => {
      const {cmdbId, payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/budgetConfig/getBudgetConfigListCount/${cmdbId}`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();

          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getListBudget = createAsyncThunk(
  "post/getListBudget",
  async (props) => {
      const { cmdbId ,pageNumber, itemsPerPage, payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/budgetConfig/getBudgetConfigList/${cmdbId}/${pageNumber}/${itemsPerPage}`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data)
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getByIdBudget = createAsyncThunk("get/getByIdBudget", async (props) => {
  const id = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
  const response = await fetch(`${proxy}/api/v1/budgetConfig/getById/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "CMTS-API-TOKEN": apiToken,
      USERNAME: username,
    },
  });
  handleResponseCode(response);
  const data = await response.json();
  return response.ok ? data : Promise.reject(data)
} catch (error) {
  console.error("Error:", error);
  throw error;
}
}
);
export const deleteBudget = createAsyncThunk(
  "post/deleteBudget",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id, cmdbId} = props;

      const response = await fetch(
        `${proxy}/api/v1/budgetConfig/delete/${id}`,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      return response.ok ? res : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error so the calling code can handle it
    }
  }
);
export const getFyList = createAsyncThunk(
  "get/getFyList",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
    const response = await fetch(
      `${proxy}/api/v1/budgetConfig/getFinancialYears/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
    }
});

const initialState={
  saveBudget: "",
  ListBudget: [],
  getDataBudget: [],
  saveLoadingBudget: false,
  ListLoadingBudget: false,
  getLoadingBudget: false,
  deleteDataBudget:"",
  deleteLoadingBudget:false,
  CountBudget:'',
  fyList:[],
  fyListLoader:false,
  CountLoadingBudget:false,
  error : false,
}
export const BudgetSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas={
        saveBudget: "",
        ListBudget: [],
        getDataBudget: [],
        saveLoadingBudget: false,
        ListLoadingBudget: false,
        getLoadingBudget: false,
        deleteDataBudget:"",
        deleteLoadingBudget:false,
        CountBudget:'',
        CountLoadingBudget:false,
        fyList:[],
        fyListLoader:false,
        error : false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveBudget.pending, (state, action) => {
        state.saveLoadingBudget = true;
        state.saveBudget = "";
        state.error = false;
      })
      .addCase(saveBudget.fulfilled, (state, action) => {
        state.saveLoadingBudget = false;
        state.saveBudget = action.payload;
        state.error = false;
      })
      .addCase(saveBudget.rejected, (state, action) => {
        state.saveLoadingBudget = false;
        state.error = true;
      })     
      .addCase(getListBudget.pending, (state, action) => {
        state.ListLoadingBudget = true;
        state.error = false;
      })
      .addCase(getListBudget.fulfilled, (state, action) => {
        state.ListLoadingBudget = false;
        state.ListBudget = action.payload;
        state.error = false;
      })
      .addCase(getListBudget.rejected, (state, action) => {
        state.ListLoadingBudget = false;
        state.error = true;
      })
      .addCase(getByIdBudget.pending, (state, action) => {
        state.getLoadingBudget = true;
        state.error = false;
      })
      .addCase(getByIdBudget.fulfilled, (state, action) => {
        state.getLoadingBudget = false;
        state.getDataBudget = action.payload;
        state.error = false;
      })
      .addCase(getByIdBudget.rejected, (state, action) => {
        state.getLoadingBudget = false;
        state.error = true;
      })
      .addCase(deleteBudget.pending, (state, action) => {
        state.deleteLoadingBudget = true;
        state.deleteDataBudget = "";
        state.error = false;
      })
      .addCase(deleteBudget.fulfilled, (state, action) => {
        state.deleteLoadingBudget = false;
        state.deleteDataBudget = action.payload;
        state.error = false;
      })
      .addCase(deleteBudget.rejected, (state, action) => {
        state.deleteLoadingBudget = false;
        state.error = true;
      })
      .addCase(getCountBudget.pending, (state, action) => {
        state.CountLoadingBudget = true;
        state.error = false;
      })
      .addCase(getCountBudget.fulfilled, (state, action) => {
        state.CountLoadingBudget = false;
        state.CountBudget = action.payload;
        state.error = false;
      })
      .addCase(getCountBudget.rejected, (state, action) => {
        state.CountLoadingBudget = false;
        state.error = true;
      })

      .addCase(getFyList.pending, (state, action) => {
        state.fyListLoader = true;
        state.error = false;
      })
      .addCase(getFyList.fulfilled, (state, action) => {
        state.fyListLoader = false;
        state.fyList = action.payload;
        state.error = false;
      })
      .addCase(getFyList.rejected, (state, action) => {
        state.fyListLoader = false;
        state.error = true;
      })
  },
});
export const { resetDataCB, setAlertError } = BudgetSlice.actions;
export default BudgetSlice.reducer;
