/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../../../../app/commonSlice";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();
export const getHostName = createAsyncThunk("get/getHostName", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/esxGroupController/untaggedEsxHostnameList`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const saveESXGroup = createAsyncThunk("post/saveESXGroup",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/esxGroupController/saveEsxGroupDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(props),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getESXTableListAndCount = createAsyncThunk("get/getESXTableListAndCount", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/esxGroupController/getEsxInventoryGroupList/${props.pageNo}/${props.limit}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(props.payload),
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getESXGroupDelete = createAsyncThunk("get/getESXGroupDelete", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/esxGroupController/deleteEsxGroupInfo/${props.id}`, {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getSoftwareGroupIncludedSoftware = createAsyncThunk("get/getSoftwareGroupIncludedSoftware", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/esxGroupController/getIncludedEsxHostDetailsList/${props.id}/${props.pageNumber}/${props.itemsPerPage}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(props.payload),
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? { rowData: props.rowData, data: data } : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getOwnersAndDivisions = createAsyncThunk("get/getOwnersAndDivisions", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getOwnersAndDivisions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getByIdESX = createAsyncThunk("get/getByIdESX", async (props) => {
  const {id} = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/esxGroupController/getById/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getSoftwareGroupDivisionCategory = createAsyncThunk("get/getSoftwareGroupDivisionCategory", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getOwnersAndDivisions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getTooltipText= createAsyncThunk("get/getTooltipText", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getListOfSoftwareWithPublisher`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

const initialState = {
  getHostNameList: [],
  getHostNameLoader: false,
  saveData: "",
  saveLoader: false,
  error: false,
  getESXTableListAndCountData: [],
  getESXTableListAndCountLoading: false,
  getESXGroupDelete: [],
  getESXGroupDeleteLoading: false,
  getSoftwareGroupIncludedSoftwares: [],
  getSoftwareGroupIncludedSoftwaresLoading: false,
  getSoftwareGroupDivisionCategoryData: [],
  getSoftwareGroupDivisionCategoryDataLoading: false,
  getOwnersAndDivisionsList: [],
  getOwnersAndDivisionsLoader: false,
  getByIdESXData:[],
  getByIdESXLoader:false,
  getTooltipData:[],
  getTooltipLoader:false,
}
export const ESXSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas = {
        getHostNameList: [],
        getHostNameLoader: false,
        saveData: "",
        saveLoader: false,
        error: false,
        getESXGroupDelete: [],
        getESXGroupDeleteLoading: false,
        getESXTableListAndCountData: [],
        getESXTableListAndCountLoading: false,
        getSoftwareGroupIncludedSoftwares: [],
        getSoftwareGroupIncludedSoftwaresLoading: false,
        getSoftwareGroupDivisionCategoryData: [],
        getSoftwareGroupDivisionCategoryDataLoading: false,
        getOwnersAndDivisionsList: [],
        getOwnersAndDivisionsLoader: false,
        getByIdESXData:[],
        getByIdESXLoader:false,
        getTooltipData:[],
        getTooltipLoader:false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostName.pending, (state, action) => {
        state.getHostNameLoader = true;
        state.getHostNameList = [];
        state.error = false;
      })
      .addCase(getHostName.fulfilled, (state, action) => {
        state.getHostNameLoader = false;
        state.getHostNameList = action.payload;
        state.error = false;
      })
      .addCase(getHostName.rejected, (state, action) => {
        state.getHostNameLoader = false;
        state.error = true;
      })
      .addCase(getESXTableListAndCount.pending, (state, action) => {
        state.getESXTableListAndCountData = [],
          state.getESXTableListAndCountLoading = true
        state.error = false;
      })
      .addCase(getESXTableListAndCount.fulfilled, (state, action) => {
        state.getESXTableListAndCountData = action.payload,
          state.getESXTableListAndCountLoading = false
        state.error = false;
      })
      .addCase(getESXTableListAndCount.rejected, (state, action) => {
        state.getESXTableListAndCountLoading = false
        state.error = true;
      })
      .addCase(getESXGroupDelete.pending, (state, action) => {
        state.getESXGroupDelete = [],
          state.getESXGroupDeleteLoading = true
        state.error = false;
      })
      .addCase(getESXGroupDelete.fulfilled, (state, action) => {
        state.getESXGroupDelete = action.payload,
          state.getESXGroupDeleteLoading = false
        state.error = false;
      })
      .addCase(getESXGroupDelete.rejected, (state, action) => {
        state.getESXGroupDeleteLoading = false
        state.error = true;
      })
      .addCase(getSoftwareGroupIncludedSoftware.pending, (state, action) => {
        state.getSoftwareGroupIncludedSoftwares = [],
          state.getSoftwareGroupIncludedSoftwaresLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupIncludedSoftware.fulfilled, (state, action) => {
        state.getSoftwareGroupIncludedSoftwares = action.payload,
          state.getSoftwareGroupIncludedSoftwaresLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupIncludedSoftware.rejected, (state, action) => {
        state.getSoftwareGroupIncludedSoftwaresLoading = false
        state.error = true;
      })
      .addCase(getSoftwareGroupDivisionCategory.pending, (state, action) => {
        state.getSoftwareGroupDivisionCategoryData = [],
        state.getSoftwareGroupDivisionCategoryDataLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupDivisionCategory.fulfilled, (state, action) => {
        state.getSoftwareGroupDivisionCategoryData = action.payload,
        state.getSoftwareGroupDivisionCategoryDataLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupDivisionCategory.rejected, (state, action) => {
        state.getSoftwareGroupDivisionCategoryDataLoading = false
        state.error = true;
      })

      .addCase(saveESXGroup.pending, (state, action) => {
        state.saveLoader = true;
        state.saveData = "";
        state.error = false;
      })
      .addCase(saveESXGroup.fulfilled, (state, action) => {
        state.saveLoader = false;
        state.saveData = action.payload;
        state.error = false;
      })
      .addCase(saveESXGroup.rejected, (state, action) => {
        state.saveLoader = false;
        state.error = true;
      })

      .addCase(getOwnersAndDivisions.pending, (state, action) => {
        state.getOwnersAndDivisionsLoader = true;
        state.getOwnersAndDivisionsList = [];
        state.error = false;
      })
      .addCase(getOwnersAndDivisions.fulfilled, (state, action) => {
        state.getOwnersAndDivisionsLoader = false;
        state.getOwnersAndDivisionsList = action.payload;
        state.error = false;
      })
      .addCase(getOwnersAndDivisions.rejected, (state, action) => {
        state.getOwnersAndDivisionsLoader = false;
        state.error = true;
      })

      .addCase(getByIdESX.pending, (state, action) => {
        state.getByIdESXLoader = true;
        state.getByIdESXData = [];
        state.error = false;
      })
      .addCase(getByIdESX.fulfilled, (state, action) => {
        state.getByIdESXLoader = false;
        state.getByIdESXData = action.payload;
        state.error = false;
      })
      .addCase(getByIdESX.rejected, (state, action) => {
        state.getByIdESXLoader = false;
        state.error = true;
      })

      .addCase(getTooltipText.pending, (state, action) => {
        state.getTooltipLoader = true;
        state.getTooltipData = [];
        state.error = false;
      })
      .addCase(getTooltipText.fulfilled, (state, action) => {
        state.getTooltipLoader = false;
        state.getTooltipData = action.payload;
        state.error = false;
      })
      .addCase(getTooltipText.rejected, (state, action) => {
        state.getTooltipLoader = false;
        state.error = true;
      })
  },
});
export const { resetDataCB, setAlertError } = ESXSlice.actions;
export default ESXSlice.reducer;
