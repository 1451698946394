import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getSoftwareInventoryList = createAsyncThunk(
    "post/getSoftwareInventoryList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/getSoftwareInventoryList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const saveInventoryAPI = createAsyncThunk(
    "post/saveInventoryAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/softwareInventory/saveInventoryDetails`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
    });

export const DeleteInventoryAPI = createAsyncThunk(
    "post/DeleteInventoryAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { id } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareInventory/deleteInventory/${id}`,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);

export const fetchEditDataAPI = createAsyncThunk(
    "post/fetchEditDataAPI",
    async (props) => {
        console.log("props", props)
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { id } = props;
        const res = await fetch(
            `${proxy}/api/v1/softwareInventory/fetchDataById/${id}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
            }
        ).then((resp) => resp.json());
        handleResponseCode(res)
        return res;
    }
);


const initialState = {
    SoftwareInventoryListData: [],
    SoftwareInventoryListLoading: false,
    saveInventoryAPIData: [],
    saveInventoryLoading: false,
    DeleteInventoryData:[],
    DeleteInventoryLoading:false,
    fetchEditData:[],
    fetchEditDataLoading:false,
    error: "",
    apiError: false,
};

export const InventorySlice = createSlice({
    name: 'SoftwareInventorySlice',
    initialState,


    reducers: {
        resetCostSliceData: () => {
            return initialState;
        },

    },
    extraReducers: (builder) => {
        builder
            //getSoftwareInventoryList
            .addCase(getSoftwareInventoryList.pending, (state, action) => {
                state.SoftwareInventoryListLoading = true;
                state.apiError = false;
            })
            .addCase(getSoftwareInventoryList.fulfilled, (state, action) => {
                state.SoftwareInventoryListData = action.payload;
                state.SoftwareInventoryListLoading = false;
                state.apiError = false;
            })
            .addCase(getSoftwareInventoryList.rejected, (state, action) => {
                state.SoftwareInventoryListLoading = false;
                state.SoftwareInventoryListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //saveInventoryAPI
            .addCase(saveInventoryAPI.pending, (state, action) => {
                state.saveInventoryLoading = true;
                state.apiError = false;
            })
            .addCase(saveInventoryAPI.fulfilled, (state, action) => {
                state.saveInventoryAPIData = action.payload;
                state.saveInventoryLoading = false;
                state.apiError = false;
            })
            .addCase(saveInventoryAPI.rejected, (state, action) => {
                state.saveInventoryLoading = false;
                state.saveInventoryAPIData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //saveInventoryAPI
            .addCase(DeleteInventoryAPI.pending, (state, action) => {
                state.DeleteInventoryLoading = true;
                state.apiError = false;
            })
            .addCase(DeleteInventoryAPI.fulfilled, (state, action) => {
                state.DeleteInventoryData = action.payload;
                state.DeleteInventoryLoading = false;
                state.apiError = false;
            })
            .addCase(DeleteInventoryAPI.rejected, (state, action) => {
                state.DeleteInventoryLoading = false;
                state.DeleteInventoryData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })

            //saveInventoryAPI
            .addCase(fetchEditDataAPI.pending, (state, action) => {
                state.fetchEditDataLoading = true;
                state.apiError = false;
            })
            .addCase(fetchEditDataAPI.fulfilled, (state, action) => {
                state.fetchEditData = action.payload;
                state.fetchEditDataLoading = false;
                state.apiError = false;
            })
            .addCase(fetchEditDataAPI.rejected, (state, action) => {
                state.fetchEditDataLoading = false;
                state.fetchEditData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            });
    },

});

export const { resetCostSliceData } = InventorySlice.actions;
export default InventorySlice.reducer;

