import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../common/GetProxy";
import { handleResponseCode } from "../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getActionPlan = createAsyncThunk(
    "post/getActionPlan",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeNonComplianceByActionPlanCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);

export const getRequestByApplication = createAsyncThunk(
    "post/getRequestByApplication",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeRequestByApplicationCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);


export const getRequestStatus = createAsyncThunk(
    "post/getRequestStatus",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeRequestStatusCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);

export const getAttachmentData = createAsyncThunk(
    "get/getAttachmentData",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const {id} = props;
      try {
        const response = await fetch(
          `${proxy}/api/v1/sirarNonCompliantController/getAttachmentPopupInfo/${id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
)

export const getCisoTableList = createAsyncThunk(
    "post/getCisoTableList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getNonCompliantFindingsSirarList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getCisoTableListCount = createAsyncThunk(
    "post/getCisoTableListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getNonCompliantFindingsSirarListCount`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

    export const SirarapproveReject = createAsyncThunk(
      "post/SirarapproveReject",
      async (props) => {
          const apiToken = cookies.get("apitoken");
          const username = cookies.get("username");
          try {
              const { payload } = props;
              console.log("payload",payload)
              const response = await fetch (`${proxy}/api/v1/sirarNonCompliantController/approvedRejectedData` ,
                  {
                      method: 'POST',
                      headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          "CMTS-API-TOKEN": apiToken,
                          USERNAME:username,
                      },
                      body: JSON.stringify(payload)
                 } 
              );
              handleResponseCode(response)
              const data = await response.json();
  
              return response.ok ? data : Promise.reject(data);
          } catch (error) {
               throw error;
          }
      }
  )

const initialState = {
    ActionPlanLoading :false,
    actionPlanData : [],
    requestApplicationLoading: false,
    requestApplicationData: [],
    requestStatusLoading: false,
    requestStatusData: [],
    cisoTableLoading: false,
    cisoTableData: [],
    cisoTableCountLoading: false,
    cisoTableCountData: [],
    cisoAttachmentLoading: false,
    cisoAttachmentData: [],
    SirarapproveRejectLoading:false,
    SirarapproveRejectData: [],
    apierror : false,
};
export const LogReviewerSlice = createSlice({
    name: "LogReviewerSlice",
    initialState,

    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getActionPlan.pending, (state, action) => {
            state.ActionPlanLoading = true;
            state.apierror = false;
          })
          .addCase(getActionPlan.fulfilled, (state, action) => {
            state.ActionPlanLoading = false;
            state.actionPlanData = action.payload;
            state.apierror = false;
          })
          .addCase(getActionPlan.rejected, (state, action) => {
            state.ActionPlanLoading = false;
            state.actionPlanData =[];
            state.apierror = true;
          })

          .addCase(getRequestByApplication.pending, (state, action) => {
            state.requestApplicationLoading = true;
            state.apierror = false;
          })
          .addCase(getRequestByApplication.fulfilled, (state, action) => {
            state.requestApplicationLoading = false;
            state.requestApplicationData = action.payload;
            state.apierror = false;
          })
          .addCase(getRequestByApplication.rejected, (state, action) => {
            state.requestApplicationLoading = false;
            state.requestApplicationData =[];
            state.apierror = true;
          })

          .addCase(getRequestStatus.pending, (state, action) => {
            state.requestStatusLoading = true;
            state.apierror = false;
          })
          .addCase(getRequestStatus.fulfilled, (state, action) => {
            state.requestStatusLoading = false;
            state.requestStatusData = action.payload;
            state.apierror = false;
          })
          .addCase(getRequestStatus.rejected, (state, action) => {
            state.requestStatusLoading = false;
            state.requestStatusData =[];
            state.apierror = true;
          })


          //Ciso Table
          .addCase(getCisoTableList.pending, (state, action) => {
            state.cisoTableLoading = true;
            state.apierror = false;
          })
          .addCase(getCisoTableList.fulfilled, (state, action) => {
            state.cisoTableLoading = false;
            state.cisoTableData = action.payload;
            state.apierror = false;
          })
          .addCase(getCisoTableList.rejected, (state, action) => {
            state.cisoTableLoading = false;
            state.cisoTableData =[];
            state.apierror = true;
          })

          //Ciso Count API
          .addCase(getCisoTableListCount.pending, (state, action) => {
            state.cisoTableCountLoading = true;
            state.apierror = false;
          })
          .addCase(getCisoTableListCount.fulfilled, (state, action) => {
            state.cisoTableCountLoading = false;
            state.cisoTableCountData = action.payload;
            state.apierror = false;
          })
          .addCase(getCisoTableListCount.rejected, (state, action) => {
            state.cisoTableCountLoading = false;
            state.cisoTableCountData =[];
            state.apierror = true;
          })

            //Ciso Count API
            .addCase(getAttachmentData.pending, (state, action) => {
                state.cisoAttachmentLoading = true;
                state.apierror = false;
            })
            .addCase(getAttachmentData.fulfilled, (state, action) => {
                state.cisoAttachmentLoading = false;
                state.cisoAttachmentData = action.payload;
                state.apierror = false;
            })
            .addCase(getAttachmentData.rejected, (state, action) => {
                state.cisoAttachmentLoading = false;
                state.cisoAttachmentData = [];
                state.apierror = true;
            })

            //SirarapproveReject
            .addCase(SirarapproveReject.pending, (state, action) => {
                state.SirarapproveRejectLoading = true;
                state.apierror = false;
            })
            .addCase(SirarapproveReject.fulfilled, (state, action) => {
                state.SirarapproveRejectLoading = false;
                state.SirarapproveRejectData = action.payload;
                state.apierror = false;
            })
            .addCase(SirarapproveReject.rejected, (state, action) => {
                state.SirarapproveRejectLoading = false;
                state.SirarapproveRejectData = [];
                state.apierror = true;
            })
    },
});

export default LogReviewerSlice.reducer;