import React from "react";
import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExtend, setTimeoutId , setLoggedUser, setPageLoading,resetLoggedUser} from "./app/commonSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import GetProxy from "./features/common/GetProxy";
import { resetHomeData } from "./features/homePage/HomeSlice";
import { setsessionToken } from "./app/commonSlice";
const proxy = GetProxy();

const IdleTimercontainer = () => {
  const idleTimeRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const onIdle = () => {
    if (location.pathname !== "/auth") {
      dispatch(setSessionExtend(true));

      const id = setTimeout(() => {
        setTimeout(() => {
          dispatch(setSessionExtend(false));
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          sessionStorage.setItem("isLoggedin",false);
          fetch(`${proxy}/auth?logout`, {
            method: "GET",
            headers: {
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
            redirect: "manual",
          }).then(() => {
              handleLogout();
              navigate("/auth");
              dispatch(resetLoggedUser());
              dispatch(resetHomeData());
              dispatch(setsessionToken(false));
            })
            .catch((err) => {
              console.error(err);
            });
        }, 1000);
      }, 60 * 1000);
      dispatch(setTimeoutId(id));
    }
  };
  const handleLogout = () => {
    cookies.remove("jwttoken", { path: "/"});
    cookies.remove("Refersh", { path: "/"});
    cookies.remove("apitoken", { path: "/"});
    cookies.remove("username", { path: "/"});
    cookies.remove("referstoken", { path: "/"});
    dispatch(setLoggedUser({ userName: "", userRole: [], token: "", showLM:'', showSirar :"" }));
    dispatch(setPageLoading(true));
    navigate("/auth"); 
  };
  useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    timeout: 29* 60 * 1000,
    onIdle: onIdle,
  });

  return <div></div>;
};

export default IdleTimercontainer;