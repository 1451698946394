import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const vmCurrentList = createAsyncThunk(
  "post/vmCurrentList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListBySearchSortAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


export const getBusinessDepartmentAPI = createAsyncThunk(
  "post/getBusinessDepartmentAPI",
  async (props) => {
    // const {divisionPayload} = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getBusinessDivision`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(divisionPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getBusinessDivisionAPI = createAsyncThunk(
  "post/getBusinessDivisionAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getBusinessDepartmentByDivision`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getVMAppAPI = createAsyncThunk(
  "post/getAppbyBusinessDepartmentAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getApplicationByDivision`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCISOaccount = createAsyncThunk(
  "post/getCISOaccount",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/CSPForAws/accountIds`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getTargetMissedItems = createAsyncThunk(
  "post/getTargetMissedItems",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/getTargetDates`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


export const ExportCSV = createAsyncThunk(
  "post/ExportCSV",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      console.log(payload);
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/exportVulnerabilityManagementListAsCSV`,
        {
          method: "POST",
          headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.blob();
      console.log("data", data)

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getSecurityDetails = createAsyncThunk("get/SecurityDetails", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/aws/get/members/details`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});
const initialState = {
  vmCurrentListData: [],
  vmCurrentListLoading: false,
  vmCurrentListMissedData: [],
  vmCurrentListLoadingMissed: false,
  error: "",
  epssScore: [],
  DistributionChart: [],
  actionPlanChart: [],
  openVulnerabiltyChartData: [],
  openVulnerabiltyChartloading: false,
  vmTop5RiskAppLoading: false,
  vmTop5RiskAppData: [],
  treeChartData: [],
  treeChartDataLoading: [],
  businessDivisionOption: [],
  businessDivisionOptionLoading: false,
  businessDepartmentOption: [],
  businessDepartmentOptionLoading: false,
  businessDepartmentOption: [],
  businessDepartmentOptionLoading: false,
  vmAppData: [],
  vmAppDataLoading: false,
  vmAccountData: [],
  vmAccountDataLoading: false,
  VulnTrendData: [],
  VulnTrendDataLoading: false,
  closevulnerabilityData: [],
  ClosedistributeDataLoading: false,
  cvidCheck: [],
  cveIdCheckLoading: false,
  getTargetMissedItemsData: [],
  getTargetMissedItemsLoading: false,
  actionByAppData: [],
  actionByAppDataLoading: false,
  openVmAppData: [],
  openVmAppDataLoading: false,
  closedVmAppData: [],
  closedVmAppDataLoading: false,
  allRiskAppCount: [],
  allRiskAppCountLoading: false,
  allRiskAppData: [],
  allRiskAppDataLoading: false,
  appTrsSeverityData: [],
  appTrsSeverityLoading: false,
  CVEIDDetails: [],
  CVEIDDetailsLoading: false,
  getTop5Vulnerability: [],
  getTop5VulnerabilityLoading: false,
  disabledCategories: [],
  secuirtyDetails : [],
  CSVDetails : [],
  CSVloading : false,
  securitydetailsLoading : false,
  detailsvmCriticalityLoading:false,
  detailsvmCriticalityData:[],

};
export const vulnerabilitiesSlice = createSlice({
  name: "vmSlice",
  initialState,

  reducers: {
    resetVulnerabilityData: () => {
      return initialState;
    },
    setDisabledCategories: (state, action) => {
      state.disabledCategories = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      //vmCurrentList
      .addCase(vmCurrentList.pending, (state, action) => {
        state.vmCurrentListLoading = true;
        state.error = "";
      })
      .addCase(vmCurrentList.fulfilled, (state, action) => {
        state.vmCurrentListData = action.payload;
        state.vmCurrentListLoading = false;
      })
      .addCase(vmCurrentList.rejected, (state, action) => {
        state.vmCurrentListLoading = false;
        state.vmCurrentListData = [];
        state.error = "something went wrong";
      })
      //businessDivisionOption
      .addCase(getBusinessDepartmentAPI.pending, (state, action) => {
        state.businessDivisionOptionLoading = true;
      })
      .addCase(getBusinessDepartmentAPI.fulfilled, (state, action) => {
        state.businessDepartmentOption = action.payload;
        state.businessDivisionOptionLoading = false;
      })
      .addCase(getBusinessDepartmentAPI.rejected, (state, action) => {
        state.businessDivisionOptionLoading = false;
      })
      //businessDepartmentOption
      .addCase(getBusinessDivisionAPI.pending, (state, action) => {
        state.businessDepartmentOptionLoading = true;
      })
      .addCase(getBusinessDivisionAPI.fulfilled, (state, action) => {
        state.businessDivisionOption = action.payload;
        state.businessDepartmentOptionLoading = false;
      })
      .addCase(getBusinessDivisionAPI.rejected, (state, action) => {
        state.businessDepartmentOptionLoading = false;
      })
      //vmAppOption
      .addCase(getVMAppAPI.pending, (state, action) => {
        state.vmAppDataLoading = true;
      })
      .addCase(getVMAppAPI.fulfilled, (state, action) => {
        state.vmAppData = action.payload;
        state.vmAppDataLoading = false;
      })
      .addCase(getVMAppAPI.rejected, (state, action) => {
        state.vmAppDataLoading = false;
      })
      //getCISOaccount
      .addCase(getCISOaccount.pending, (state, action) => {
        state.vmAccountDataLoading = true;
      })
      .addCase(getCISOaccount.fulfilled, (state, action) => {
        state.vmAccountData = action.payload;
        state.vmAccountDataLoading = false;
      })
      .addCase(getCISOaccount.rejected, (state, action) => {
        state.vmAccountDataLoading = false;
      })
      //getTargetMissedItems
      .addCase(getTargetMissedItems.pending, (state, action) => {
        state.getTargetMissedItemsLoading = true;
      })
      .addCase(getTargetMissedItems.fulfilled, (state, action) => {
        state.getTargetMissedItemsData = action.payload;
        state.getTargetMissedItemsLoading = false;
      })
      .addCase(getTargetMissedItems.rejected, (state, action) => {
        state.getTargetMissedItemsLoading = false;
      })

      //SecurityDetails
      .addCase(getSecurityDetails.pending, (state, action) => {
        state.securitydetailsLoading = true;
      })
      .addCase(getSecurityDetails.fulfilled, (state, action) => {
        state.secuirtyDetails = action.payload;
        state.securitydetailsLoading = false;
      })
      .addCase(getSecurityDetails.rejected, (state, action) => {
        state.securitydetailsLoading = false;
      })

      .addCase(ExportCSV.pending, (state, action) => {
        state.CSVloading = true;
      })
      .addCase(ExportCSV.fulfilled, (state, action) => {
        state.CSVDetails = action.payload;
        state.CSVloading = false;
      })
      .addCase(ExportCSV.rejected, (state, action) => {
        state.CSVloading = false;
      })
  },
});

export const { resetVulnerabilityData, setDisabledCategories } = vulnerabilitiesSlice.actions;
export default vulnerabilitiesSlice.reducer;