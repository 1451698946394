import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { propTypes } from 'react-bootstrap/esm/Image';
import Cookies from 'universal-cookie';
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from '../../../../app/utilities/helpers';
const proxy = GetProxy();
const cookies = new Cookies();


    export const BITreeMapAPI = createAsyncThunk(
        "get/BITreeMapAPI", 
        async () => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
        const response = await fetch(
            `${proxy}/api/v1/businessInfoConfig/getBusinessInfoTreeMappedData`, 
            {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "CMTS-API-TOKEN": Apitoken,
                USERNAME: User,
            },
        })  
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data);
      } catch(error) {
        console.error("Error:", error);
        throw error;
      }
    });

    export const BIGroupPopupAPI = createAsyncThunk(
        "post/BIGroupPopupAPI",
        async (props) => {
            console.log("props",props)
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const { groupAbbreviation } = props;
            const res = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getGroupAbbreviationPopUpInfo/${groupAbbreviation}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
                },
              }
            ).then((resp) => resp.json());
            handleResponseCode(res)
            return res;
          }
        );

    export const BIDepartmentPopupAPI = createAsyncThunk(
        "post/BIDepartmentPopupAPI",
        async (props) => {
            console.log("props",props)
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const { groupAbbreviation ,businessDepartment } = props;
            const res = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getBusinessDepartmentPopUpInfo/${groupAbbreviation}/${businessDepartment}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
                },
              }
            ).then((resp) => resp.json());
            handleResponseCode(res)
            return res;
          }
        );

    export const BIDivisionPopupAPI = createAsyncThunk(
        "post/BIDivisionPopupAPI",
        async (props) => {
            console.log("props",props)
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const { groupAbbreviation ,businessDepartment ,businessDivision} = props;
            const res = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getBusinessDivisionPopUpInfo/${groupAbbreviation}/${businessDepartment}/${businessDivision}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
                },
              }
            ).then((resp) => resp.json());
            handleResponseCode(res)
            return res;
          }
        );

        export const FyAnalistUserListAPI = createAsyncThunk(
          "get/FyAnalistUserListAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getFinanceAnalystRoleUserList`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });

      export const FyAnalistSelectListAPI = createAsyncThunk(
        "post/FyAnalistSelectListAPI",
        async (props) => {
            console.log("props",props)
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const { Division } = props;
            const res = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getFinanceAnalystListByDivison/${Division}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
                },
              }
            ).then((resp) => resp.json());
            handleResponseCode(res)
            return res;
          }
        );

        export const DivisionUserListAPI = createAsyncThunk(
          "get/DivisionUserListAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/get/businessDivision`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });

      export const saveBasedOnRoles = createAsyncThunk(
        "post/saveBasedOnRoles",
        async (props) => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
            const { payload } = props;
    
            const response = await fetch(`${proxy}/api/v1/businessInfoConfig/saveBasedOnRoles`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,
    
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json(); 
            return response.ok ?  data : Promise.reject(data);
        } catch(error) {
            console.log("Error", error);
            throw(error)
        }
        });

        export const groupNameAPI = createAsyncThunk(
          "get/groupNameAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/get/groupName`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });

        export const getGroupHeadUserAPI = createAsyncThunk(
          "get/getGroupHeadUserAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getGroupHeadRoleUserList`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });

        export const ApplicationPManagerAPI = createAsyncThunk(
          "get/ApplicationPManagerAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getAppNameAndPrimaryManager`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });
      
      export const getSecondaryOwnerListAPI = createAsyncThunk(
        "post/getSecondaryOwnerListAPI",
        async (props) => {
            console.log("props",props)
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const { AppName } = props;
            const res = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getSecondaryOwnerListByAppName/${AppName}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
                },
              }
            ).then((resp) => resp.json());
            handleResponseCode(res)
            return res;
          }
        );
        
        export const getUserListSecOwnerAPI = createAsyncThunk(
          "get/getUserListSecOwnerAPI", 
          async () => {
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          try {
          const response = await fetch(
              `${proxy}/api/v1/businessInfoConfig/getUserListForSecondaryOwner`, 
              {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "CMTS-API-TOKEN": Apitoken,
                  USERNAME: User,
              },
          })  
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
        } catch(error) {
          console.error("Error:", error);
          throw error;
        }
      });

const initialState = {
    BITreeMapData: [],
    BITreeMapLoading: false,
    BIGroupPopupData: [],
    BIGroupPopupLoading: false,
    BIDepartmentPopupData: [],
    BIDepartmentPopupLoading: false,
    BIDivisionPopupData:[],
    BIDivisionPopupLoading:false,
    FyAnalistUserListData:[],
    FyAnalistUserListLoading:false,
    DivisionUserListData:[],
    DivisionUserListLoading:false,
    FyAnalistSelectListData:[],
    FyAnalistSelectListLoading:false,
    saveBasedOnRolesData:[],
    saveBasedOnRolesLoading:false,
    groupNameData:[],
    groupNameLoading:false,
    getGroupHeadUserData:[],
    getGroupHeadUserLoading:false,
    ApplicationPManagerData:[],
    ApplicationPManagerLoading:false,
    getSecondaryOwnerListData:[],
    getSecondaryOwnerListLoading:false,
    getUserListSecOwnerData:[],
    getUserListSecOwnerLoading:false,
    error : "",
    apiError : false,
};

export const BusinessInfoSlice = createSlice({
    name: 'busInfoSlice',
    initialState,


    reducers: {
       resetCostSliceData: () => {
        return initialState;
       },
       setcloudTableData: (state, action) => {
        state.cloudTableData = action.payload;
       },
       setApiError: (state, action) => {
        state.apiError = false;
       },
    },
    extraReducers: (builder) => {
        builder
            // treechart
            .addCase(BITreeMapAPI.pending, (state, action) => {
                state.BITreeMapLoading = true;
                state.apiError = false;
            })
            .addCase(BITreeMapAPI.fulfilled, (state, action) => {
                state.BITreeMapData = action.payload;
                state.BITreeMapLoading = false;
                state.apiError = false;
            })
            .addCase(BITreeMapAPI.rejected, (state, action) => {
                state.BITreeMapLoading = false;
                state.BITreeMapData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //group chart popup
            .addCase(BIGroupPopupAPI.pending, (state, action) => {
                state.BIGroupPopupLoading = true;
                state.apiError = false;
            })
            .addCase(BIGroupPopupAPI.fulfilled, (state, action) => {
                state.BIGroupPopupData = action.payload;
                state.BIGroupPopupLoading = false;
                state.apiError = false;
            })
            .addCase(BIGroupPopupAPI.rejected, (state, action) => {
                state.BIGroupPopupLoading = false;
                state.BIGroupPopupData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //department chart popup
            .addCase(BIDepartmentPopupAPI.pending, (state, action) => {
                state.BIDepartmentPopupLoading = true;
                state.apiError = false;
            })
            .addCase(BIDepartmentPopupAPI.fulfilled, (state, action) => {
                state.BIDepartmentPopupData = action.payload;
                state.BIDepartmentPopupLoading = false;
                state.apiError = false;
            })
            .addCase(BIDepartmentPopupAPI.rejected, (state, action) => {
                state.BIDepartmentPopupLoading = false;
                state.BIDepartmentPopupData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //division chart popup
            .addCase(BIDivisionPopupAPI.pending, (state, action) => {
                state.BIDivisionPopupLoading = true;
                state.apiError = false;
            })
            .addCase(BIDivisionPopupAPI.fulfilled, (state, action) => {
                state.BIDivisionPopupData = action.payload;
                state.BIDivisionPopupLoading = false;
                state.apiError = false;
            })
            .addCase(BIDivisionPopupAPI.rejected, (state, action) => {
                state.BIDivisionPopupLoading = false;
                state.BIDivisionPopupData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //Finance Analyst list
            .addCase(FyAnalistUserListAPI.pending, (state, action) => {
                state.FyAnalistUserListLoading = true;
                state.apiError = false;
            })
            .addCase(FyAnalistUserListAPI.fulfilled, (state, action) => {
                state.FyAnalistUserListData = action.payload;
                state.FyAnalistUserListLoading = false;
                state.apiError = false;
            })
            .addCase(FyAnalistUserListAPI.rejected, (state, action) => {
                state.FyAnalistUserListLoading = false;
                state.FyAnalistUserListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //Finance Analyst selected list
            .addCase(FyAnalistSelectListAPI.pending, (state, action) => {
                state.FyAnalistSelectListLoading = true;
                state.apiError = false;
            })
            .addCase(FyAnalistSelectListAPI.fulfilled, (state, action) => {
                state.FyAnalistSelectListData = action.payload;
                state.FyAnalistSelectListLoading = false;
                state.apiError = false;
            })
            .addCase(FyAnalistSelectListAPI.rejected, (state, action) => {
                state.FyAnalistSelectListLoading = false;
                state.FyAnalistSelectListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //division user list
            .addCase(DivisionUserListAPI.pending, (state, action) => {
                state.DivisionUserListLoading = true;
                state.apiError = false;
            })
            .addCase(DivisionUserListAPI.fulfilled, (state, action) => {
                state.DivisionUserListData = action.payload;
                state.DivisionUserListLoading = false;
                state.apiError = false;
            })
            .addCase(DivisionUserListAPI.rejected, (state, action) => {
                state.DivisionUserListLoading = false;
                state.DivisionUserListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //saveBasedOnRoles user list
            .addCase(saveBasedOnRoles.pending, (state, action) => {
                state.saveBasedOnRolesLoading = true;
                state.apiError = false;
            })
            .addCase(saveBasedOnRoles.fulfilled, (state, action) => {
                state.saveBasedOnRolesData = action.payload;
                state.saveBasedOnRolesLoading = false;
                state.apiError = false;
            })
            .addCase(saveBasedOnRoles.rejected, (state, action) => {
                state.saveBasedOnRolesLoading = false;
                state.saveBasedOnRolesData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //groupNameAPI user list
            .addCase(groupNameAPI.pending, (state, action) => {
                state.groupNameLoading = true;
                state.apiError = false;
            })
            .addCase(groupNameAPI.fulfilled, (state, action) => {
                state.groupNameData = action.payload;
                state.groupNameLoading = false;
                state.apiError = false;
            })
            .addCase(groupNameAPI.rejected, (state, action) => {
                state.groupNameLoading = false;
                state.groupNameData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //getGroupHeadUserAPI user list
            .addCase(getGroupHeadUserAPI.pending, (state, action) => {
                state.getGroupHeadUserLoading = true;
                state.apiError = false;
            })
            .addCase(getGroupHeadUserAPI.fulfilled, (state, action) => {
                state.getGroupHeadUserData = action.payload;
                state.getGroupHeadUserLoading = false;
                state.apiError = false;
            })
            .addCase(getGroupHeadUserAPI.rejected, (state, action) => {
                state.getGroupHeadUserLoading = false;
                state.getGroupHeadUserData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //ApplicationPManagerAPI user list
            .addCase(ApplicationPManagerAPI.pending, (state, action) => {
                state.ApplicationPManagerLoading = true;
                state.apiError = false;
            })
            .addCase(ApplicationPManagerAPI.fulfilled, (state, action) => {
                state.ApplicationPManagerData = action.payload;
                state.ApplicationPManagerLoading = false;
                state.apiError = false;
            })
            .addCase(ApplicationPManagerAPI.rejected, (state, action) => {
                state.ApplicationPManagerLoading = false;
                state.ApplicationPManagerData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //getSecondaryOwnerListAPI user list
            .addCase(getSecondaryOwnerListAPI.pending, (state, action) => {
                state.getSecondaryOwnerListLoading = true;
                state.apiError = false;
            })
            .addCase(getSecondaryOwnerListAPI.fulfilled, (state, action) => {
                state.getSecondaryOwnerListData = action.payload;
                state.getSecondaryOwnerListLoading = false;
                state.apiError = false;
            })
            .addCase(getSecondaryOwnerListAPI.rejected, (state, action) => {
                state.getSecondaryOwnerListLoading = false;
                state.getSecondaryOwnerListData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            })
            //getUserListSecOwnerAPI user list
            .addCase(getUserListSecOwnerAPI.pending, (state, action) => {
                state.getUserListSecOwnerLoading = true;
                state.apiError = false;
            })
            .addCase(getUserListSecOwnerAPI.fulfilled, (state, action) => {
                state.getUserListSecOwnerData = action.payload;
                state.getUserListSecOwnerLoading = false;
                state.apiError = false;
            })
            .addCase(getUserListSecOwnerAPI.rejected, (state, action) => {
                state.getUserListSecOwnerLoading = false;
                state.getUserListSecOwnerData = [];
                state.error = "Something went wrong";
                state.apiError = true;
            });
        },
    
    });
    
    export const {resetCostSliceData,setcloudTableData, setApiError} = BusinessInfoSlice.actions;
    export default BusinessInfoSlice.reducer;