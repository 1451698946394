import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { propTypes } from 'react-bootstrap/esm/Image';
import Cookies from 'universal-cookie';
import GetProxy from '../../../common/GetProxy';
import { handleResponseCode } from '../../../../app/utilities/helpers';
const proxy = GetProxy();
const cookies = new Cookies();

export const cloudConversionList = createAsyncThunk(
    "post/cloudConversionList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
             const response = await fetch(`${proxy}/api/v1/CurrencyConversionController/getCurrencyConversionDetailsList/${pageNumber}/${itemsPerPage}`,

                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
)

export const cloudConversionCount = createAsyncThunk(
    "post/cloudConversionCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch (`${proxy}/api/v1/CurrencyConversionController/getCurrencyConversionDetailsListCount` ,
                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
)

export const cloudConversionSave = createAsyncThunk(
    "post/cloudConversionSave",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch (`${proxy}/api/v1/CurrencyConversionController/saveOrUpdateCurrencyConversion` ,
                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
)

export const cloudConversionInfo = createAsyncThunk(
    "get/cloudConversionInfo",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const { id } = props;
      const res = await fetch(
        `${proxy}/api/v1/CurrencyConversionController/getCurrencyConversionInfoList/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      ).then((resp) => resp.json());
      handleResponseCode(res)
      return res;
    }
  );

  export const CalculationList = createAsyncThunk(
    "post/CalculationList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
             const response = await fetch(`${proxy}/api/v1/CurrencyConversionController/getCostCalculationDetailsList/${pageNumber}/${itemsPerPage}`,

                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
)

export const CalculationListCount = createAsyncThunk(
    "post/CalculationListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
             const response = await fetch(`${proxy}/api/v1/CurrencyConversionController/getCostingCalculationCount`,

                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
)

export const CalculationSave = createAsyncThunk(
    "post/CalculationSave",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch (`${proxy}/api/v1/CurrencyConversionController/saveOrUpdateCostingCalculation` ,
                {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME:username,
                    },
                    body: JSON.stringify(payload)
               } 
            );
            handleResponseCode(response)
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
             throw error;
        }
    }
);

export const LatestEDPAPI = createAsyncThunk(
    "get/LatestEDPAPI", 
    async () => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
    const response = await fetch(
        `${proxy}/api/v1/CurrencyConversionController/getLatestEdpDiscount`, 
        {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
        },
    })  
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch(error) {
    console.error("Error:", error);
    throw error;
  }
});


const initialState = {
    cloudConversionData:[],
    cloudConversionLoading:false,
    cloudConversionCountData:[],
    cloudConversionCountLoading:false,
    cloudConversionSaveData:[],
    cloudConversionSaveLoading:false,
    cloudConversionInfoData:[],
    cloudConversionInfoLoading:false,
    CalculationListData:[],
    CalculationListLoading:false,
    CalculationListCountData:[],
    CalculationListCountLoading:false,
    CalculationSaveData:[],
    CalculationSaveLoading:false,
    LatestEDPAPIData:[],
    LatestEDPAPILoading:false,
    error : "",
    apiError : false,
}

export const CloudCostConfigSlice = createSlice ({
    name: "CloudCostConfigSlice",
    initialState,

    reducers : {
        resetCloudCostConfigData: () => {
            return initialState;
        },
    },
    extraReducers : (builder) => {
        builder
        .addCase(cloudConversionList.pending, (state, action) => {
            state.cloudConversionLoading = true;
            state.apiError = false;
        })
        .addCase(cloudConversionList.fulfilled, (state, action) => {
            state.cloudConversionData = action.payload;
            state.cloudConversionLoading = false;
            state.apiError = false;
        })
        .addCase(cloudConversionList.rejected, (state, action) => {
            state.cloudConversionLoading = false;
            state.cloudConversionData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(cloudConversionCount.pending, (state, action) => {
            state.cloudConversionCountLoading = true;
            state.apiError = false;
        })
        .addCase(cloudConversionCount.fulfilled, (state, action) => {
            state.cloudConversionCountData = action.payload;
            state.cloudConversionCountLoading = false;
            state.apiError = false;
        })
        .addCase(cloudConversionCount.rejected, (state, action) => {
            state.cloudConversionCountLoading = false;
            state.cloudConversionCountData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(cloudConversionSave.pending, (state, action) => {
            state.cloudConversionSaveLoading = true;
            state.apiError = false;
        })
        .addCase(cloudConversionSave.fulfilled, (state, action) => {
            state.cloudConversionSaveData = action.payload;
            state.cloudConversionSaveLoading = false;
            state.apiError = false;
        })
        .addCase(cloudConversionSave.rejected, (state, action) => {
            state.cloudConversionSaveLoading = false;
            state.cloudConversionSaveData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(cloudConversionInfo.pending, (state, action) => {
            state.cloudConversionInfoLoading = true;
            state.apiError = false;
        })
        .addCase(cloudConversionInfo.fulfilled, (state, action) => {
            state.cloudConversionInfoData = action.payload;
            state.cloudConversionInfoLoading = false;
            state.apiError = false;
        })
        .addCase(cloudConversionInfo.rejected, (state, action) => {
            state.cloudConversionInfoLoading = false;
             state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(CalculationList.pending, (state, action) => {
            state.CalculationListLoading = true;
            state.apiError = false;
        })
        .addCase(CalculationList.fulfilled, (state, action) => {
            state.CalculationListData = action.payload;
            state.CalculationListLoading = false;
            state.apiError = false;
        })
        .addCase(CalculationList.rejected, (state, action) => {
            state.CalculationListLoading = false;
            state.CalculationListData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(CalculationListCount.pending, (state, action) => {
            state.CalculationListCountLoading = true;
            state.apiError = false;
        })
        .addCase(CalculationListCount.fulfilled, (state, action) => {
            state.CalculationListCountData = action.payload;
            state.CalculationListCountLoading = false;
            state.apiError = false;
        })
        .addCase(CalculationListCount.rejected, (state, action) => {
            state.CalculationListCountLoading = false;
            state.CalculationListCountData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        .addCase(CalculationSave.pending, (state, action) => {
            state.CalculationSaveLoading = true;
            state.apiError = false;
        })
        .addCase(CalculationSave.fulfilled, (state, action) => {
            state.CalculationSaveData = action.payload;
            state.CalculationSaveLoading = false;
            state.apiError = false;
        })
        .addCase(CalculationSave.rejected, (state, action) => {
            state.CalculationSaveLoading = false;
            state.CalculationSaveData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        })
        //LatestEDPAPI
        .addCase(LatestEDPAPI.pending, (state, action) => {
            state.LatestEDPAPILoading = true;
            state.apiError = false;
        })
        .addCase(LatestEDPAPI.fulfilled, (state, action) => {
            state.LatestEDPAPIData = action.payload;
            state.LatestEDPAPILoading = false;
            state.apiError = false;
        })
        .addCase(LatestEDPAPI.rejected, (state, action) => {
            state.LatestEDPAPILoading = false;
            state.LatestEDPAPIData = [];
            state.error = "Something went wrong";
            state.apiError = true;
        });

    },
});

export const {resetCloudCostConfigData} = CloudCostConfigSlice.actions;
export default CloudCostConfigSlice.reducer;