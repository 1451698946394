import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoggedUser, setPageLoading} from "./app/commonSlice";
import Container from "./app/common-components/Container";
import ContainerLoader from "./app/common-components/ContainerLoader";
import { userRestrictedAccess } from "./app/utilities/constants";
import {setRedirectPath} from "./app/utilities/helpers";
import Cookies from "universal-cookie";
import GetProxy from "../src/features/common/GetProxy.js";
import { decrypt } from "./app/utilities/helpers";
const PrivateRoute = (props) => {
  const cookies = new Cookies();
  const location = useLocation();
  const pathName = get(location, "pathname", "");
  const { loggedUser, pageLoading,apiProxy} = useSelector((state) => state.common);
  const { userName, userRole, token} = loggedUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const proxy = GetProxy();
  const checkUserAccess = (userRole) => {
    if (
      document.title === "MAS - Access Denied" &&
      pathName !== "/access-denied"
    )
      document.title = "MAS";
    // if (userRole === "User") {
    //   const matchedPathIndex = userRestrictedAccess.indexOf(pathName);
    //   if (matchedPathIndex !== -1) {
    //     navigate("/access-denied");
    //     return false;
    //   }
    // }
    pathName === "/home" && navigate("/home");
    dispatch(setPageLoading(false));
  };
  const jwttoken = cookies.get("jwttoken");
  const refershtoken = cookies.get("Refersh");
  const sessionToken = useSelector(state => state.common.sessionToken);
  const getLoggedUser = async () => {
    const cookies = new Cookies();
    await fetch(`${proxy}/getTopHeader`, {
      method: "GET",
      redirect: "manual",
      cache: "no-store",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: jwttoken,
        "Refresh-Token": refershtoken,
      },
    })
      .then((data) => {
        if (data.status === 200) {
          setRedirectPath();
          return data.json();
        } 
      }).then((response) => {
        if (response && pathName !== "/" && refershtoken!==null && response.refreshToken !== "undefined") {
          const userName = response.encodedUsername;
          const userRole = response.role;
          const showLM = response.showLM;
          const showSirar = response.showSirar;
          const displayNamedecoded = decrypt(response.displayName)
          const displayName = displayNamedecoded;
          const token = response.token;
          const refreshToken = response.refreshToken;
          dispatch(setLoggedUser({userName,userRole,token,displayName, showLM, showSirar}));
          sessionStorage.setItem("isLoggedin",true)
          if(sessionToken===false)
          {
            cookies.set("apitoken", token, { path: "/"});
          }

          cookies.set("username", userName, { path: "/"});
          cookies.set("referstoken", refreshToken, { path: "/" }); 
          return
        } else {
          cookies.remove("jwttoken", { path: "/"});
          cookies.remove("Refersh", { path: "/"});
          cookies.remove("apitoken", { path: "/"});
          cookies.remove("username", { path: "/"});
          cookies.remove("referstoken", { path: "/"});
          sessionStorage.setItem("lastVistedURL", pathName);
          sessionStorage.setItem("isLoggedin",false);
          navigate("/auth");
        }
        return; 
      })
      .catch((error) => {
        console.log("err", error);
        navigate("/auth");
      });
  };

  useEffect(() => {
    getLoggedUser();
  }, []);
  useEffect(() => {
    if (pathName === "/access-denied") document.title = "MAS - Access Denied";
    userName !== "" && userRole?.length !== 0 && token !== "" && checkUserAccess(userRole);
  }, [pathName, userName, userRole, token, pageLoading]);

  return <>{pageLoading ? <ContainerLoader /> : <Container /> }</>;
};

export default PrivateRoute;