/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const CloudScapeList = createAsyncThunk(
  "post/CloudScapeList",
  async (props) => {
    try {
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/list/${pageNumber}/${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log('Error', error);
    throw(error)
  }
});
export const CloudScapeCount = createAsyncThunk(
  "post/CloudScapeCount",
  async (props) => {
    try{
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/listCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log("Error", error);
    throw(error);
  }
  }
);
export const CloudScapeDrilldownList = createAsyncThunk(
  "post/CloudScapeDrilldownList",
  async (props) => {
    try {
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/list/${pageNumber}/${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log('Error', error);
    throw(error)
  }
});
export const CloudScapeDrilldownListCount = createAsyncThunk(
  "post/CloudScapeDrilldownListCount",
  async (props) => {
    try{
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/listCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log("Error", error);
    throw(error);
  }
  }
);

export const targetDateDrilldownList = createAsyncThunk(
  "post/targetDateDrilldownList",
  async (props) => {
    try {
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/getMissedTargetDateDetailsList/${pageNumber}/${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log('Error', error);
    throw(error)
  }
});
export const targetDateDrilldownListCount = createAsyncThunk(
  "post/targetDateDrilldownListCount",
  async (props) => {
    try{
    const { pageNumber, itemsPerPage, payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsCloudScape/getMissedTargetDateDetailsListForCount`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(res);
    const data = res.json();
    return res.ok ? data : Promise.reject(data);
  } catch(error) {
    console.log("Error", error);
    throw(error);
  }
  }
);

export const cloudScapeNonComplianceByaccountIdAPI = createAsyncThunk(
  "post/securityHubNonComplianceByaccountId",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getNonComplianceByAccountId`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
    const data = response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.log(`Error : ${error}`);
  }
}
);
export const CloudScapeResourcesType = createAsyncThunk(
  "post/CloudScapeResourcesType",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getCloudScapeNonCompliantByResourceType`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
      const data = response.json();
      return response.ok  ? data : Promise.reject(data);
  } catch(error){
    console.log("Error in getting data", error);
  }
  }
);
export const getcloudScapeTargetMissedItems = createAsyncThunk(
  "post/getcloudScapeTargetMissedItems",
  async (props) => {
    try {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`${proxy}/api/v1/awsCloudScape/getTargetDateMissed`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getcloudScapeTargetDueTrend = createAsyncThunk(
  "post/getcloudScapeTargetDueTrend",
  async (props) => {
    try {
      const { payload } = props;    
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(`${proxy}/api/v1/awsCloudScape/getTargetDateDueTrend`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCompliantTypeChartData = createAsyncThunk(
  "post/getCompliantTypeChartData",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getCloudScapeComplianceStatus`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
      const data = response.json();
      return response.ok  ? data : Promise.reject(data);
  } catch(error){
    console.log("Error in getting data", error);
  }
  }
);

export const getNonComplianceActionPlanChartData = createAsyncThunk(
  "post/getNonComplianceActionPlanChartData",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getCloudScapeNonComplianceByActionPlan`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
      const data = response.json();
      return response.ok  ? data : Promise.reject(data);
  } catch(error){
    console.log("Error in getting data", error);
  }
  }
);

export const getNonComplianceAppCrticality = createAsyncThunk(
  "post/getNonComplianceAppCrticality",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getCloudScapeNonComplianceByAppCriticality`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
      const data = response.json();
      return response.ok  ? data : Promise.reject(data);
  } catch(error){
    console.log("Error in getting data", error);
  }
  }
);

export const getNonComplianceApplication = createAsyncThunk(
  "post/getNonComplianceApplication",
  async (props) => {
    try {
    const {payload}  = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/awsCloudScape/getCloudScapeNonComplianceByApplication`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    )
    handleResponseCode(response);
      const data = response.json();
      return response.ok  ? data : Promise.reject(data);
  } catch(error){
    console.log("Error in getting data", error);
  }
  }
);
const  initialState = {
  securityHubListFixed: [],
  securityHubCurrentList: [],
  securityHubCurrentCount : [],
  securityHubDrilldownList: [],
  targetDateDrilldownListData: [],
  securityHubDrilldownCount : [],
  targetDateDrilldownCount : [],
  securityHubCount:[],  
  tableLoading: false,
  CurentShTableListLoading: false,
  CurentShTableCountLoading: false,
  DrilldowntableLoading: false,
  DrilldownShTableLoading: false,
  targetDateDrilldownLoading: false,
  targetDateSHDrilldownLoading: false,
  error: '',
  nonComplianceById:[],
  nonComplianceByIdLoading:false,
  nonComplianceByType:[],
  nonComplianceByTypeLoading:false,  
  cloudScapeTargetMissedItems:[],
  cloudScapeTargetMissedItemsLoading:false,
  cloudScapeTargetDueTrend:[],
  cloudScapeTargetDueTrendLoading:false,
  securityHubOpenFindings:[],
  securityHubOpenFindingsLoading:false,
  securityHubClosedFindings:[],
  securityHubClosedFindingsLoading:false,
  DistributionChart:[],
  FindingDistributionloading:false,
  compliantTypeLoading:false,
  compliantTypeData:[],
  actionPlanChartLoading:false,
  actionPlanChartData:[],
  appCrticalityLoading:false,
  appCrticalityChartData:[],
  appChartLoading:false,
  appChartData:[],
  apierror : false,
}
export const CloudScapeSlice = createSlice({
  name: "shfSlice",
  initialState,
  reducers: {
    resetSecurityHubData: () => {
      return initialState;
     },
     setError: {
      reducer(state, action) {
        state.apierror = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CloudScapeList.pending, (state, action) => {
        state.CurentShTableListLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(CloudScapeList.fulfilled, (state, action) => {
        state.CurentShTableListLoading = false;
        state.securityHubCurrentList = action.payload;
        state.apierror = false;
      })
      .addCase(CloudScapeList.rejected, (state, action) => {
        state.CurentShTableListLoading = false;
        state.securityHubCurrentList =[];
        state.error = "Something went wrong"
        state.apierror = true;
      })

      .addCase(CloudScapeCount.pending, (state, action) => {
        state.CurentShTableCountLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(CloudScapeCount.fulfilled, (state, action) => {
        state.CurentShTableCountLoading = false;
        state.securityHubCurrentCount = action.payload;
        state.apierror = false;
      })
      .addCase(CloudScapeCount.rejected, (state, action) => {
        state.CurentShTableCountLoading = false;
        state.securityHubCurrentCount = [];
        state.error = "Something went wrong"
        state.apierror = true;
      })
      .addCase(CloudScapeDrilldownList.pending, (state, action) => {
        state.DrilldowntableLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(CloudScapeDrilldownList.fulfilled, (state, action) => {
        state.DrilldowntableLoading = false;
        state.securityHubDrilldownList = action.payload;
        state.apierror = false;
      })
      .addCase(CloudScapeDrilldownList.rejected, (state, action) => {
        state.DrilldowntableLoading = false;
        state.securityHubDrilldownList =[];
        state.error = "Something went wrong"
        state.apierror = true;
      })

      .addCase(CloudScapeDrilldownListCount.pending, (state, action) => {
        state.DrilldownShTableLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(CloudScapeDrilldownListCount.fulfilled, (state, action) => {
        state.DrilldownShTableLoading = false;
        state.securityHubDrilldownCount = action.payload;
        state.apierror = false;
      })
      .addCase(CloudScapeDrilldownListCount.rejected, (state, action) => {
        state.DrilldownShTableLoading = false;
        state.securityHubDrilldownCount = [];
        state.error = "Something went wrong"
        state.apierror = true;
      })

      .addCase(targetDateDrilldownList.pending, (state, action) => {
        state.targetDateSHDrilldownLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(targetDateDrilldownList.fulfilled, (state, action) => {
        state.targetDateSHDrilldownLoading = false;
        state.targetDateDrilldownListData = action.payload;
        state.apierror = false;
      })
      .addCase(targetDateDrilldownList.rejected, (state, action) => {
        state.targetDateSHDrilldownLoading = false;
        state.targetDateDrilldownListData =[];
        state.error = "Something went wrong"
        state.apierror = true;
      })

      .addCase(targetDateDrilldownListCount.pending, (state, action) => {
        state.targetDateDrilldownLoading = true;
        state.error = "";
        state.apierror = false;
      })
      .addCase(targetDateDrilldownListCount.fulfilled, (state, action) => {
        state.targetDateDrilldownLoading = false;
        state.targetDateDrilldownCount = action.payload;
        state.apierror = false;
      })
      .addCase(targetDateDrilldownListCount.rejected, (state, action) => {
        state.targetDateDrilldownLoading = false;
        state.targetDateDrilldownCount = [];
        state.error = "Something went wrong"
        state.apierror = true;
      })

      .addCase(cloudScapeNonComplianceByaccountIdAPI.pending, (state, action) => {
        state.nonComplianceByIdLoading = true;
        state.apierror = false;
      })
      .addCase(cloudScapeNonComplianceByaccountIdAPI.fulfilled, (state, action) => {
        if (action.payload.status === 500) {
          state.nonComplianceById = [];
          state.nonComplianceByIdLoading = false;
        } else {
          state.nonComplianceByIdLoading = false;
          state.nonComplianceById = action.payload;
        }
        state.apierror = false;
      })
      
      .addCase(cloudScapeNonComplianceByaccountIdAPI.rejected, (state, action) => {
        state.nonComplianceByIdLoading = false;
        state.nonComplianceById = [];
        state.apierror = true;
      })

      .addCase(CloudScapeResourcesType.pending, (state, action) => {
        state.nonComplianceByTypeLoading = true;
        state.apierror = false;
      })
      .addCase(CloudScapeResourcesType.fulfilled, (state, action) => {
        if (action.payload.status === 500) {
          state.nonComplianceByType = [];
          state.nonComplianceByTypeLoading = false;
        } else {
          state.nonComplianceByTypeLoading = false;
          state.nonComplianceByType = action.payload;
        }
        state.apierror = false;
      })
      
      .addCase(CloudScapeResourcesType.rejected, (state, action) => {
        state.nonComplianceByTypeLoading = false;
        state.nonComplianceByType = [];
        state.apierror = true;
      })


      .addCase(getcloudScapeTargetMissedItems.pending, (state, action) => {
        state.cloudScapeTargetMissedItemsLoading = true;
        state.apierror = false;
      })
      .addCase(getcloudScapeTargetMissedItems.fulfilled, (state, action) => {
          state.cloudScapeTargetMissedItems = action.payload;
          state.cloudScapeTargetMissedItemsLoading = false;
          state.apierror = false;       
      })    
      .addCase(getcloudScapeTargetMissedItems.rejected, (state, action) => {
        state.cloudScapeTargetMissedItemsLoading = false;
        state.cloudScapeTargetMissedItems = [];
        state.apierror = true;
      })

      .addCase(getcloudScapeTargetDueTrend.pending, (state, action) => {
        state.cloudScapeTargetDueTrendLoading = true;
        state.apierror = false;
      })
      .addCase(getcloudScapeTargetDueTrend.fulfilled, (state, action) => {
        state.cloudScapeTargetDueTrend=action.payload;
        state.cloudScapeTargetDueTrendLoading=false;    
        state.apierror = false;
      })    
      .addCase(getcloudScapeTargetDueTrend.rejected, (state, action) => {
          state.cloudScapeTargetDueTrend=[];
          state.cloudScapeTargetDueTrendLoading=false;
          state.apierror = true;
      })

      .addCase(getCompliantTypeChartData.pending, (state, action) => {
        state.compliantTypeLoading = true;
        state.apierror = false;
      })
      .addCase(getCompliantTypeChartData.fulfilled, (state, action) => {
        state.compliantTypeData = action.payload;
        state.compliantTypeLoading = false;
        state.apierror = false;
      })
      .addCase(getCompliantTypeChartData.rejected, (state, action) => {
        state.compliantTypeLoading = false;
        state.apierror = true;
      })

      .addCase(getNonComplianceActionPlanChartData.pending, (state, action) => {
        state.actionPlanChartLoading = true;
        state.apierror = false;
      })
      .addCase(getNonComplianceActionPlanChartData.fulfilled, (state, action) => {
        state.actionPlanChartData = action.payload;
        state.actionPlanChartLoading = false;
        state.apierror = false;
      })
      .addCase(getNonComplianceActionPlanChartData.rejected, (state, action) => {
        state.actionPlanChartLoading = false;
        state.apierror = true;
      })

      .addCase(getNonComplianceAppCrticality.pending, (state, action) => {
        state.appCrticalityLoading = true;
        state.apierror = false;
      })
      .addCase(getNonComplianceAppCrticality.fulfilled, (state, action) => {
        state.appCrticalityChartData = action.payload;
        state.appCrticalityLoading = false;
        state.apierror = false;
      })
      .addCase(getNonComplianceAppCrticality.rejected, (state, action) => {
        state.appCrticalityLoading = false;
        state.apierror = true;
      })

      .addCase(getNonComplianceApplication.pending, (state, action) => {
        state.appChartLoading = true;
        state.apierror = false;
      })
      .addCase(getNonComplianceApplication.fulfilled, (state, action) => {
        state.appChartData = action.payload;
        state.appChartLoading = false;
        state.apierror = false;
      })
      .addCase(getNonComplianceApplication.rejected, (state, action) => {
        state.appChartLoading = false;
        state.apierror = true;
      })

  },
});
export const {resetSecurityHubData, setError} = CloudScapeSlice.actions;
export default CloudScapeSlice.reducer;
