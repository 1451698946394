/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const saveGoLive = createAsyncThunk(
  "post/saveGoLive",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {payload,cmdbId} = props;

      const response = await fetch(
        `${proxy}/api/v1/alertConfig/editAlertConfig/${cmdbId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getGoLive = createAsyncThunk(
  "get/getGoLive",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
    const response = await fetch(
      `${proxy}/api/v1/alertConfig/getById/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
    }
});

const initialState={
  liveGOGetData: [],
  liveGOSave: [],
  liveGOGetLoading: false,
  liveGOSaveLoading: false,
  error : false,
}
export const GoLiveSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataAppGoLive: (state) => {
      const resetDatas={
        liveGOGetData: [],
        liveGOSave: [],
        liveGOGetLoading: false,
        liveGOSaveLoading: false,
        error : false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveGoLive.pending, (state, action) => {
        state.liveGOSaveLoading = true;
        state.liveGOSave = [];
        state.error = false;
      })
      .addCase(saveGoLive.fulfilled, (state, action) => {
        state.liveGOSaveLoading = false;
        state.liveGOSave = action.payload;
        state.error = false;
      })
      .addCase(saveGoLive.rejected, (state, action) => {
        state.liveGOSaveLoading = false;
        state.liveGOSave=[];
        state.error = true;
      })
      .addCase(getGoLive.pending, (state, action) => {
        state.liveGOGetData = [];
        state.liveGOGetLoading=true;
        state.error = false;
      })
      .addCase(getGoLive.fulfilled, (state, action) => {
        state.liveGOGetData = action.payload;
        state.liveGOGetLoading=false;
        state.error = false;
      })
      .addCase(getGoLive.rejected, (state, action) => {
        state.liveGOGetData = [];
        state.liveGOGetLoading=false;
        state.error = true;
      })
  },
});
export const { resetDataAppGoLive, setAlertError } = GoLiveSlice.actions;
export default GoLiveSlice.reducer;
