import { createSlice } from "@reduxjs/toolkit";

const getAppCode = createSlice({
    name: 'AppCode',
    initialState: {
        getApp: '',
        getModule:'',
        getTableData:'',
        homeSelectedApp: {}
    },
    reducers: {
      setAppCode: (state, action) => {
        state.getApp = action.payload;
      },
      setModule:(state, action) => {
        state.getModule = action.payload;
      },
      setTableData:(state, action) => {
        state.getTableData = action.payload;
      },
      updateSelectedApp: (state, action) => {
        state.homeSelectedApp = action.payload
      },
      resetHomeData: (state, action) => {
        let initialState =  {
          getApp: '',
          getModule:state.getModule,
          getTableData:'',
          homeSelectedApp: {}
      };
      return initialState;
      }
      
    }
  });
  
  export const { updateSelectedApp, setAppCode,setModule,setTableData, resetHomeData } = getAppCode.actions;
  export default getAppCode.reducer;
