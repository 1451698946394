import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getActionPlan = createAsyncThunk(
    "post/getActionPlan",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeNonComplianceByActionPlanCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);

export const getRequestByApplication = createAsyncThunk(
    "post/getRequestByApplication",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeRequestByApplicationCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);


export const getRequestStatus = createAsyncThunk(
    "post/getRequestStatus",
    async (props) => {
        try {
            const { payload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(
                `${proxy}/api/v1/awsCloudScape/getCloudScapeRequestStatusCISO`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error in getting data", error);
        }
    }
);

const initialState = {
    ActionPlanLoading :false,
    actionPlanData : [],
    requestApplicationLoading: false,
    requestApplicationData: [],
    requestStatusLoading: false,
    requestStatusData: [],
    apierror : false,
};
export const CisoCloudScapeSlice = createSlice({
    name: "CisoCloudScapeSlice",
    initialState,

    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getActionPlan.pending, (state, action) => {
            state.ActionPlanLoading = true;
            state.apierror = false;
          })
          .addCase(getActionPlan.fulfilled, (state, action) => {
            state.ActionPlanLoading = false;
            state.actionPlanData = action.payload;
            state.apierror = false;
          })
          .addCase(getActionPlan.rejected, (state, action) => {
            state.ActionPlanLoading = false;
            state.actionPlanData =[];
            state.apierror = true;
          })

          .addCase(getRequestByApplication.pending, (state, action) => {
            state.requestApplicationLoading = true;
            state.apierror = false;
          })
          .addCase(getRequestByApplication.fulfilled, (state, action) => {
            state.requestApplicationLoading = false;
            state.requestApplicationData = action.payload;
            state.apierror = false;
          })
          .addCase(getRequestByApplication.rejected, (state, action) => {
            state.requestApplicationLoading = false;
            state.requestApplicationData =[];
            state.apierror = true;
          })

          .addCase(getRequestStatus.pending, (state, action) => {
            state.requestStatusLoading = true;
            state.apierror = false;
          })
          .addCase(getRequestStatus.fulfilled, (state, action) => {
            state.requestStatusLoading = false;
            state.requestStatusData = action.payload;
            state.apierror = false;
          })
          .addCase(getRequestStatus.rejected, (state, action) => {
            state.requestStatusLoading = false;
            state.requestStatusData =[];
            state.apierror = true;
          })
    },
});

export default CisoCloudScapeSlice.reducer;