import { capitalize } from "lodash";
import React from "react";
import { Alert } from "react-bootstrap";

const AlertBox = (props) => {
  return (
    <Alert
    id="Alertid"    
      show={props.alert}
      variant={props.type}
      dismissible={props.onClose ? true : false}
      transition={false}
      onClose={() => props.onClose(false)}
      className={props.CustomclassName?props.CustomclassName:""}
    >
      {(typeof props.showType !== "undefined" && props.showType) && <div className="alert_img">
        <strong>{capitalize(props.type)}!</strong>
      </div>}
      {
        typeof props.showType == "undefined" &&
        <div className="alert_img">
          <strong>{capitalize(props.type)}!</strong>
        </div>
      }
      <div className="alert_msg_contain" data-testid="alert-message">
        <span className="alert-msg">
          {props.message instanceof Function ? props.message() : props.message}
        </span>
      </div>
    </Alert>
  );
};

export default AlertBox;
