import Spinner from "react-bootstrap/Spinner";
import React from "react";

function Spinners(props) {
  return (
   <div className={props.className ? props.className : "spin_overlay"}>
    <Spinner animation="border" variant="primary" />
  </div>
  )
}
export default Spinners;
