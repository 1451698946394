import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../common/GetProxy";
import { handleResponseCode } from "../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const userManagementListAPI = createAsyncThunk(
  "post/userManagementListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageLimit, pageNo } = props;
      const response = await fetch(
        `${proxy}/api/v1/licence/user/licenceUserListForSearchWithPagination/${pageNo}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json(); 
          
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const userManagementCountAPI = createAsyncThunk(
  "post/userManagementCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNo, pageLimit } = props;
      const response = await fetch(
        `${proxy}/api/v1/licence/user/licenceUserCountForSearchWithPagination`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const userListData = createAsyncThunk(
  "post/userListData",
  async () => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/directory/get/members/details`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const rolemappingListAPI = createAsyncThunk(
  "post/rolemappingListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageLimit, pageNo } = props;
      const response = await fetch(
        `${proxy}/api/v1/roleMapping/getRoleMappingList/${payload.pageNo}/${payload.pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload.payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json(); 
          
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const rolemappingCountAPI = createAsyncThunk(
  "post/rolemappingCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNo, pageLimit } = props;
      const response = await fetch(
        `${proxy}/api/v1/roleMapping/getRoleMappingListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload.payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const rolemappingEdit = createAsyncThunk(
  "post/rolemappingEdit",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/roleMapping/saveOrUpdateRoleMapping`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const rolemappingTest = createAsyncThunk(
  "post/rolemappingTest",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/directory/testConnectionForGroups`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const rolemappingSave = createAsyncThunk(
  "post/rolemappingSave",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/directory/fetchUsersByGroups`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const rolemappinggrouplist = createAsyncThunk(
  "post/rolemappinggrouplist",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/directory/getAllGroups`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();             
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    toolsPlugin: [],
    toolName: [],
    userManagementLoadPage:false,
    userManagementListData:[],
    userManagementListDataLoading:false,
    userManagementListCount:[],
    userManagementListCountLoading:false,
    userListDetailsData:[],
    userListDetailsDataLoader:false,
    rolemappingData:[],
    rolemappingLoader:false,
    rolemappingcountData:[],
    rolemappingcountLoader:false,
    rolemappingsaveData:[],
    rolemappingsaveLoader:false,
    rolemappingeditData:[],
    rolemappingeditLoader:false,
    rolemappingtestData:[],
    rolemappingtestLoader:false,
    rolemappingtestData:[],
    rolemappingtestLoader:false,
    rolemappinggrouplistData:[],
    rolemappinggrouplistLoader:false,
    error : false,
  },
  reducers: {
    setToolsPlugin: {
      reducer(state, action) {
        state.toolsPlugin = action.payload;
      },
    },
    setToolsName: {
      reducer(state, action) {
        state.toolName = action.payload;
      },
    },
    setUserManagement: {
      reducer(state, action) {
        state.userManagementLoadPage = action.payload;
      },
    },
    setErrorAlert: {
      reducer(state, action) {
        state.error = false;
      },
    },
  },

  extraReducers: (builder) => {
    builder
      //userManagementListAPI
      .addCase(userManagementListAPI.pending, (state, action) => {
        state.userManagementListDataLoading = true;
        state.error = false;
      })
      .addCase(userManagementListAPI.fulfilled, (state, action) => {
        state.userManagementListDataLoading = false;
        state.userManagementListData = action.payload;
        state.error = false;
      })
      .addCase(userManagementListAPI.rejected, (state, action) => {
        state.userManagementListDataLoading = false;
        state.error = true;
      })

      //userManagementCountAPI
      .addCase(userManagementCountAPI.pending, (state, action) => {
        state.userManagementListCountLoading = true;
        state.error = false;
      })
      .addCase(userManagementCountAPI.fulfilled, (state, action) => {
        state.userManagementListCountLoading = false;
        state.userManagementListCount = action.payload;
        state.error = false;
      })
      .addCase(userManagementCountAPI.rejected, (state, action) => {
        state.userManagementListCountLoading = false;
        state.error = true;
      })

      //rolemappingEdit
      .addCase(rolemappingEdit.pending, (state, action) => {
        state.rolemappingeditLoader = true;
        state.error = false;
      })
      .addCase(rolemappingEdit.fulfilled, (state, action) => {
        state.rolemappingeditLoader = false;
        state.rolemappingeditData = action.payload;
        state.error = false;
      })
      .addCase(rolemappingEdit.rejected, (state, action) => {
        state.rolemappingeditLoader = false;
        state.error = true;
      })
      //rolemappingSave
      .addCase(rolemappingSave.pending, (state, action) => {
        state.rolemappingsaveLoader = true;
        state.error = false;
      })
      .addCase(rolemappingSave.fulfilled, (state, action) => {
        state.rolemappingsaveLoader = false;
        state.rolemappingsaveData = action.payload;
        state.error = false;
      })
      .addCase(rolemappingSave.rejected, (state, action) => {
        state.rolemappingsaveLoader = false;
        state.error = true;
      })
      //rolemappinggrouplist
      .addCase(rolemappinggrouplist.pending, (state, action) => {
        state.rolemappinggrouplistLoader = true;
        state.error = false;
      })
      .addCase(rolemappinggrouplist.fulfilled, (state, action) => {
        state.rolemappinggrouplistLoader = false;
        state.rolemappinggrouplistData = action.payload;
        state.error = false;
      })
      .addCase(rolemappinggrouplist.rejected, (state, action) => {
        state.rolemappinggrouplistLoader = false;
        state.error = true;
      })
      //rolemappingListAPI
      .addCase(rolemappingListAPI.pending, (state, action) => {
        state.rolemappingLoader = true;
        state.error = false;
      })
      .addCase(rolemappingListAPI.fulfilled, (state, action) => {
        state.rolemappingLoader = false;
        state.rolemappingData = action.payload;
        state.error = false;
      })
      .addCase(rolemappingListAPI.rejected, (state, action) => {
        state.rolemappingLoader = false;
        state.error = true;
      })

      //rolemappingCountAPI
      .addCase(rolemappingCountAPI.pending, (state, action) => {
        state.rolemappingcountLoader = true;
        state.error = false;
      })
      .addCase(rolemappingCountAPI.fulfilled, (state, action) => {
        state.rolemappingcountLoader = false;
        state.rolemappingcountData = action.payload;
        state.error = false;
      })
      .addCase(rolemappingCountAPI.rejected, (state, action) => {
        state.rolemappingcountLoader = false;
        state.error = true;
      })

      //userDetailsList
      .addCase(userListData.pending, (state, action) => {
        state.userListDetailsDataLoader = true;
        state.error = false;
      })
      .addCase(userListData.fulfilled, (state, action) => {
        state.userListDetailsDataLoader = false;
        state.userListDetailsData = action.payload;
        state.error = false;
      })
      .addCase(userListData.rejected, (state, action) => {
        state.userListDetailsDataLoader = false;
        state.userListDetailsData = [];
        state.error = true;
      })
      //rolemappingTest
      .addCase(rolemappingTest.pending, (state, action) => {
        state.rolemappingtestLoader = true;
        state.error = false;
      })
      .addCase(rolemappingTest.fulfilled, (state, action) => {
        state.rolemappingtestLoader = false;
        state.rolemappingtestData = action.payload;
        state.error = false;
      })
      .addCase(rolemappingTest.rejected, (state, action) => {
        state.rolemappingtestLoader = false;
        state.userListDetailsData = [];
        state.error = true;
      });
  }
});

export const { setToolsPlugin, setToolsName,setUserManagement,setErrorAlert } = configurationSlice.actions;

export default configurationSlice.reducer;
