import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import GetProxy from "../common/GetProxy";
import { handleResponseCode } from "../../app/utilities/helpers";
const cookies = new Cookies();
const proxy = GetProxy();
export const backendConfig = createAsyncThunk(
  "get/backendConfig",
  async () => {
    const res = await fetch(
      `${proxy}/initial/licence/getSetUpStatus`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
        },
      }
    ).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
  }
);
export const passwordConfig = createAsyncThunk(
  "get/passwordConfig",
  async () => {
    const res = await fetch(
      `${proxy}/initial/setupConfig`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
        },
      }
    ).then((resp) => resp.text());
    handleResponseCode(res);
    return res;
  }
);
export const licenseConfighome = createAsyncThunk(
  "get/licenseConfighome",
  async (props) => {
    const res = await fetch(
      `${proxy}/initial/licence/validation`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
        },
        body: props,
      }
    ).then((resp) => resp.text());
    handleResponseCode(res);
    return res;
  }
);
export const wizardConfighome = createAsyncThunk(
  "get/wizardConfighome",
  async (props) => {
    const { payload } = props;
    const res = await fetch(
      `${proxy}/initial/setupConfig/wizard`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
        },
        body: JSON.stringify(payload),
      }
    ).then((resp) => resp.text());
    handleResponseCode(res);
    return res;
  }
);
export const buildSuccessRate = createAsyncThunk(
  "post/buildSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/buildSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const deploymentSuccessRate = createAsyncThunk(
  "post/deploymentSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/deploymentSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const flowPredictabilityRate = createAsyncThunk(
  "post/flowPredictabilityRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/flowPredictabilityRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const refreshTokenApi = createAsyncThunk(
  "get/refreshToken",
  async () => {
    const refreshToken = cookies.get("referstoken");
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
   try {
    const response = await fetch(
      `${proxy}/api/v1/refreshToken`,
      {
        method: "POST",
        headers: {
          Refreshtoken:refreshToken,
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        
      }
    )   
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

);

export const getVersion = createAsyncThunk(
  "post/getVersion",
  async (props) => {
    try {
      const response = await fetch(`${proxy}/version`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
      );
      handleResponseCode(response);
      const data =  response.text();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const getNotificationData = createAsyncThunk(
  "post/getNotificationData",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Notification/getUserData`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      
      );
      handleResponseCode(response);
      const data = await response.json();
      
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const getMessageRead = createAsyncThunk(
  "post/getMessageRead",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Notification/updateMessageReadStatus`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data =   await response.text();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const Homeapislice = createSlice({
  name: "homeapislice",
  initialState: {
    buildSuccessRatedata: 0,
    buildSuccessRateLoading: false,
    deploymentSuccessRatedata: 0,
    deploymentSuccessRateLoading: false,
    flowPredictabilityRatedata: 0,
    flowPredictabilityRateLoading:false,
    backendConfigData:false,
    backendConfigLoding:false,
    passwordConfigData:[],
    passwordConfigLoding:false,
    licenseConfighomeData:[],
    licenseConfighomeLoding:false,
    wizardConfighomeData:[],
    wizardConfighomeLoding:false,
    BEversion : [],
    versionLoading : false,
    apiError : false,
  },

  reducers: {
    setApiError: (state, action) => {
      state.apiError = false;
     },
  },
  extraReducers: (builder) => {
    builder
      //buildSuccessRate
      .addCase(buildSuccessRate.pending, (state, action) => {
        state.buildSuccessRateLoading = true;
        state.apiError = false;
      })
      .addCase(buildSuccessRate.fulfilled, (state, action) => {
        state.buildSuccessRatedata = action.payload;
        state.buildSuccessRateLoading = false;
        state.apiError = false;
      })
      .addCase(buildSuccessRate.rejected, (state, action) => {
        state.buildSuccessRateLoading = false;
        state.buildSuccessRatedata = 0;
        state.apiError = true;
      })
      //deploymentSuccessRate
      .addCase(deploymentSuccessRate.pending, (state, action) => {
        state.deploymentSuccessRateLoading = true;
        state.apiError = false;
      })
      .addCase(deploymentSuccessRate.fulfilled, (state, action) => {
        state.deploymentSuccessRatedata = action.payload;
        state.deploymentSuccessRateLoading = false;
        state.apiError = false;
      })
      .addCase(deploymentSuccessRate.rejected, (state, action) => {
        state.deploymentSuccessRateLoading = false;
        state.apiError = true;
      })

      //flowPredictabilityRate
      .addCase(flowPredictabilityRate.pending, (state, action) => {
        state.flowPredictabilityRateLoading = true;
        state.apiError = false;
      })
      .addCase(flowPredictabilityRate.fulfilled, (state, action) => {
        state.flowPredictabilityRatedata = action.payload;
        state.flowPredictabilityRateLoading = false;
        state.apiError = false;
      })
      .addCase(flowPredictabilityRate.rejected, (state, action) => {
        state.flowPredictabilityRateLoading = false;
        state.flowPredictabilityRatedata = 0;
        state.apiError = true;
      })
       //backendConfig
       .addCase(backendConfig.pending, (state, action) => {
        state.backendConfigLoding = true;
        state.apiError = false;
      })
      .addCase(backendConfig.fulfilled, (state, action) => {
        state.backendConfigData = action.payload;
        state.backendConfigLoding = false;
        state.apiError = false;
      })
      .addCase(backendConfig.rejected, (state, action) => {
        state.backendConfigLoding = false;
        state.backendConfigData = true;
        state.apiError = true;
      })
      //passwordConfig
      .addCase(passwordConfig.pending, (state, action) => {
        state.passwordConfigLoding = true;
        state.apiError = false;
      })
      .addCase(passwordConfig.fulfilled, (state, action) => {
        state.passwordConfigData = action.payload;
        state.passwordConfigLoding = false;
        state.apiError = false;
      })
      .addCase(passwordConfig.rejected, (state, action) => {
        state.passwordConfigLoding = false;
        state.passwordConfigData = [];
        state.apiError = true;
      })
      //licenseConfighome
      .addCase(licenseConfighome.pending, (state, action) => {
        state.licenseConfighomeLoding = true;
        state.apiError = false;
      })
      .addCase(licenseConfighome.fulfilled, (state, action) => {
        state.licenseConfighomeData = action.payload;
        state.licenseConfighomeLoding = false;
        state.apiError = false;
      })
      .addCase(licenseConfighome.rejected, (state, action) => {
        state.licenseConfighomeLoding = false;
        state.licenseConfighomeData = [];
        state.apiError = true;
      })
      //wizardConfighome
      .addCase(wizardConfighome.pending, (state, action) => {
        state.wizardConfighomeLoding = true;
        state.apiError = false;
      })
      .addCase(wizardConfighome.fulfilled, (state, action) => {
        state.wizardConfighomeData = action.payload;
        state.wizardConfighomeLoding = false;
        state.apiError = false;
      })
      .addCase(wizardConfighome.rejected, (state, action) => {
        state.wizardConfighomeLoding = false;
        state.wizardConfighomeData = [];
        state.apiError = true;
      })

      .addCase(getMessageRead.pending, (state, action) => {
        state.getMessageReadLoading = true;
        state.apiError = false;
      })
      .addCase(getMessageRead.fulfilled, (state, action) => {
        state.getMessageReaddata = action.payload;
        state.getMessageReadLoading = false;
        state.apiError = false;
      })
      .addCase(getMessageRead.rejected, (state, action) => {
        state.getMessageReadLoading = false;
        state.getMessageReaddata = [];
        state.apiError = true;
      })
      .addCase(getNotificationData.pending, (state, action) => {
        state.getNotificationDataLoading = true;
        state.apiError = false;
      })
      .addCase(getNotificationData.fulfilled, (state, action) => {
        state.getNotificationDatainfo = action.payload;
        state.getNotificationDataLoading = false;
        state.apiError = false;
      })
      .addCase(getNotificationData.rejected, (state, action) => {
        state.getNotificationDataLoading = false;
        state.getNotificationDatainfo = [];
        state.apiError = true;
      })
      .addCase(getVersion.pending, (state, action) => {
        state.versionLoading = true;
        state.apiError = false;
      })
      .addCase(getVersion.fulfilled, (state, action) => {
        state.BEversion = action.payload;
        state.versionLoading = false;
        state.apiError = false;
      })
      .addCase(getVersion.rejected, (state, action) => {
        state.versionLoading = false;
        state.BEversion = [];
        state.apiError = true;
      });
  },
});

export const {setApiError} = Homeapislice.actions;
export default Homeapislice.reducer;