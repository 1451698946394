import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const gettoolsList = createAsyncThunk(
  "post/gettoolsList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tools/getToolsListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const gettoolsCount = createAsyncThunk(
    "post/getDataCount",
    async (props) => {
      const { payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const response = await fetch(
          `${proxy}/api/v1/tools/getToolsListCount`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": apiToken,
              USERNAME: username,
            },
            body: JSON.stringify(payload),
          }
        );
        handleResponseCode(response);
        const data = await response.json();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );

  export const getConfigToolsList = createAsyncThunk(
    "post/gettoolid",
    async (props) => {
      const { pageNumber, itemsPerPage } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const response = await fetch(
          `${proxy}/api/v1/tools/listByItemsPerPage/${pageNumber}/${itemsPerPage}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": apiToken,
              USERNAME: username,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.json();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );

const ToolSlice = createSlice({
  name: "ToolSlice",
  initialState: {
    resetData: [],
    getToolsListData: [],
    toolsCountData: [],
    toolsidDta: [],
    toolsLoading: false,
    toolscountLoading: false,
    error :[],
    apiError : false,
  },
  reducers: {
    setTools: {
      reducer(state, action) {
        state.resetData = action.payload;
      },
    },
    setapiError: {
      reducer(state, action) {
        state.apiError = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gettoolsList.pending, (state, action) => {
        state.toolsLoading = true;
        state.apiError = false;
      })
      .addCase(gettoolsList.fulfilled, (state, action) => {
        state.getToolsListData = action.payload;
        state.toolsLoading = false;
        state.apiError = false;
      })
      .addCase(gettoolsList.rejected, (state, action) => {
        state.toolsLoading = false;
        state.getToolsListData = [];
        state.error = "Something went wrong";
        state.apiError = true;
      })

      .addCase(gettoolsCount.pending, (state, action) => {
        state.toolscountLoading = true;
        state.apiError = false;
      })
      .addCase(gettoolsCount.fulfilled, (state, action) => {
        state.toolsCountData = action.payload;
        state.toolscountLoading = false;
        state.apiError = false;
      })
      .addCase(gettoolsCount.rejected, (state, action) => {
        state.toolscountLoading = false;
        state.toolsCountData = [];
        state.error = "Something went wrong";
        state.apiError = true;
      })

      .addCase(getConfigToolsList.pending, (state, action) => {
        state.toolsLoading = true;
        state.apiError = false;
      })
      .addCase(getConfigToolsList.fulfilled, (state, action) => {
        state.toolsidDta = action.payload;
        state.toolsLoading = false;
        state.apiError = false;
      })
      .addCase(getConfigToolsList.rejected, (state, action) => {
        state.toolsLoading = false;
        state.toolsidDta = [];
        state.error = "Something went wrong";
        state.apiError = true;
      })
  },
});

export const { setTools, setapiError } = ToolSlice.actions;

export default ToolSlice.reducer;
