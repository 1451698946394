import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const businessInfoList = createAsyncThunk(
  "post/getData",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/businessInfoConfig/list/BusinessInfoConfigSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const businessInfoCount = createAsyncThunk(
  "post/getDatas",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        ` ${proxy}/api/v1/businessInfoConfig/list/BusinessInfoConfigSearchCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const AppList = createAsyncThunk("post/getDatasApp", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const { userName, userRole } = props;
    const response = await fetch(`${proxy}/api/v1/cmdb/${userName}/${userRole[0]}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
});

export const BusinessInfoSave = createAsyncThunk("post/save", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const { userName, payload } = props;

    const response = await fetch(
      `${proxy}/api/v1/businessInfoConfig/saveOrUpdateBusinessConfigInfo/${userName}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const Businessdelete = createAsyncThunk(
  "post/deleteinfo",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, id } = props;
      const response = await fetch(
        `${proxy}/api/v1/businessInfoConfig/delete/${id}/${userName}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
);

export const BusinessEdit = createAsyncThunk(
  "post/getdataedit",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id } = props;
      const response = await fetch(`${proxy}/api/v1/businessInfoConfig/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getTableFilter = createAsyncThunk(
  "post/getTableFilter",
  async () => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/businessInfoConfig/list/BusinessInfoConfig/division`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const userInfoListApi = createAsyncThunk(
  "post/userInfoListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/LDAPDashboard/UserInfoForCmdb/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const userInfoCountApi = createAsyncThunk(
  "post/userInfoCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/LDAPDashboard/UserInfoForCmdbCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const serverInfoListApi = createAsyncThunk(
  "post/serverInfoListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/serverConfigInfo/listByDevice/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const serverInfoCountApi = createAsyncThunk(
  "post/serverInfoCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/serverConfigInfo/listCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountListApi = createAsyncThunk(
  "post/cloudAccountListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/awsIam/CSPAccountListForSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountCountApi = createAsyncThunk(
  "post/cloudAccountCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/awsIam/CSPAccountListForSearchCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const cloudAccountTestConnection = createAsyncThunk(
  "post/cloudAccountTestConnection",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/awsIam/checkAccountForTestConnection`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(props),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const osLicenseConfigListApi = createAsyncThunk(
  "post/osLicenseConfigListApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/licenceConfigForServerRest/getListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const osLicenseConfigCountApi = createAsyncThunk(
  "post/osLicenseConfigCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/licenceConfigForServerRest/getListCountWithSearchAndPagination`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const isLdapUserCheckAPI = createAsyncThunk(
  "post/isLdapUserCheckAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/LDAPDashboard/checkLDAPConfig`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const ldapSyncAPI = createAsyncThunk(
  "post/ldapSyncAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/LDAPDashboard/LDAPDashboardDataSave`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const checkCloudAccountsTestConnection = createAsyncThunk(
  "post/checkAccountForTestConnection",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {payload} = props
      console.log(payload);
      const response = await fetch(`${proxy}/api/v1/billingController/checkAccountForTestConnection`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username, 
        },
          body: JSON.stringify(payload),
       
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ImportCSVCloudAccounts = createAsyncThunk(
  "post/ImportCSVCloudAccounts",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const {formData} = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/saveCloudAccountIdFromCSV`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: formData,
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ExportCloudAccounttemplate = createAsyncThunk(
  "post/ExportCloudAccounttemplate",
  async () => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/awsIam/templateCSVForCloudAccount`,
        {
          method: "GET",
          headers: {
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      );
      const data = await response.blob();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getSmtpToogle = createAsyncThunk(
  "post/getSmtpToogle",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { status } = props;
      const response = await fetch(
        `${proxy}/api/v1/smtpConfig/enableOrDisableSmtp/${status}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      );
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getApplication = createAsyncThunk(
  "get/getApplication",
  async () => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    console.log(username);
    try {
    const response = await fetch(
      `${proxy}/api/v1/Landing/getApplications/${username}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
    }
});
const CMDBSlice = createSlice({
  name: "CmdbId",
  initialState: {
    applicationList:[],
    appLoader:false,
    getCmdbId: "",
    businessInfoData: [],
    bILoading: false,
    businessInfoDataCount: [],
    Applist: [],
    ApplistLoading: false,
    bICountLoading: false,
    saveLoading: false,
    DataSave: "",
    deleteLoading: false,
    editLoading: false,
    responseData: [],
    tableFilterData: [],
    error: "",
    errorEdit:"",
    errorCount :"",
    errorApp : "",
    errorSave : "",
    errorDelete :"",
    DataDelete:{},
    userInfoListLoading:false,
    userInfoListData:[],
    userInfoCountLoading:false,
    userInfoCountData:[],
    serverInfoListLoading:false,
    serverInfoListData:[],
    serverInfoCountLoading:false,
    serverInfoCountData:[],
    cloudAccountListLoading:false,
    cloudAccountListData:[],
    cloudAccountCountLoading:false,
    cloudAccountCountData:[],
    osLicenseConfigListLoading:false,
    osLicenseConfigListData:[],
    osLicenseConfigCountLoading:false,
    osLicenseConfigCountData:[],
    isLdapUser:false,
    isLdapUserLoading:false,
    ldapSyncdata:[],
    ldapSyncLoading: false,
    ldapSyncdataFulFill: false,
    alertError : false,
    cloudAccountTestConnection: [],
    cloudAccountTestConnectionLoading: false,
    importCSVloading : false,
    importCSVfullfilled : false,
    importCSVData : [],
    smtptoggleLoading : false,
    smtpToggleData : [],
  },
  reducers: {
    setCmdbId: (state, action) => {
      state.getCmdbId = action.payload;
    },
    setalertError: (state, action) => {
      state.alertError = false;
    },
    resetDataSave: (state) => {
      state.DataSave = "";
    },
    resetldapSyncStatus: (state) => {
      state.ldapSyncdataFulFill = false;
      state.ldapSyncdata=''
    },
    resetresponseData: (state) => {
      state.responseData = [];
    },
    resetImportCSVData: (state) => {
      state.importCSVfullfilled = false;
      state.importCSVData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(businessInfoList.pending, (state, action) => {
        state.bILoading = true;
        state.alertError = false;
      })
      .addCase(businessInfoList.fulfilled, (state, action) => {
        state.businessInfoData = action.payload;
        state.bILoading = false;
        state.alertError = false;
      })
      .addCase(businessInfoList.rejected, (state, action) => {
        state.bILoading = false;
        state.businessInfoData = [];
        state.error = "Something went wrong";
        state.alertError = true;
      })

      .addCase(businessInfoCount.pending, (state, action) => {
        state.bICountLoading = true;
        state.alertError = false;
      })
      .addCase(businessInfoCount.fulfilled, (state, action) => {
        state.businessInfoDataCount = action.payload;
        state.bICountLoading = false;
        state.alertError = false;
      })
      .addCase(businessInfoCount.rejected, (state, action) => {
        state.bICountLoading = false;
        state.businessInfoDataCount = [];
        state.errorCount = "Something went wrong";
        state.alertError = true;
      })

      .addCase(AppList.pending, (state, action) => {
        state.ApplistLoading = true;
        state.alertError = false;
      })
      .addCase(AppList.fulfilled, (state, action) => {
        state.Applist = action.payload;
        state.ApplistLoading = false;
        state.alertError = false;
      })
      .addCase(AppList.rejected, (state, action) => {
        state.ApplistLoading = false;
        state.Applist = [];
        state.errorApp = "Something went wrong";
        state.alertError = true;
      })

      .addCase(BusinessInfoSave.pending, (state, action) => {
        state.DataSave = "";
        state.saveLoading = true;
        state.alertError = false;
      })
      .addCase(BusinessInfoSave.fulfilled, (state, action) => {
        state.DataSave = action.payload;
        state.saveLoading = false;
        state.alertError = false;
      })
      .addCase(BusinessInfoSave.rejected, (state, action) => {
        state.saveLoading = false;
        state.DataSave = "";
        state.errorSave = "Something went wrong";
        state.alertError = true;
      })

      .addCase(Businessdelete.pending, (state, action) => {
        state.deleteLoading = true;
        state.alertError = false;
      })
      .addCase(Businessdelete.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.DataDelete=action.payload;
        state.alertError = false;
      })
      .addCase(Businessdelete.rejected, (state, action) => {
        state.deleteLoading = false;
        state.errorDelete = "Something went wrong";
        state.alertError = true;
      })

      .addCase(BusinessEdit.pending, (state, action) => {
        state.editLoading = true;
        state.alertError = false;
      })
      .addCase(BusinessEdit.fulfilled, (state, action) => {
        state.responseData = action.payload;
        state.editLoading = false;
        state.alertError = false;
      })
      .addCase(BusinessEdit.rejected, (state, action) => {
        state.editLoading = false;
        state.responseData = [];
        state.errorEdit = "Something went error";
        state.alertError = true;
      })

      .addCase(getTableFilter.fulfilled, (state, action) => {
        state.tableFilterData = action.payload;
        state.alertError = false;
      })

      // userInfo List
      .addCase(userInfoListApi.pending, (state, action) => {
        state.userInfoListLoading = true;
        state.alertError = false;
      })
      .addCase(userInfoListApi.fulfilled, (state, action) => {
        state.userInfoListData = action.payload;
        state.userInfoListLoading = false;
        state.ldapSyncdata=[];
        state.ldapSyncdataFulFill=false;
        state.alertError = false;
      })
      .addCase(userInfoListApi.rejected, (state, action) => {
        state.userInfoListLoading = false;
        state.userInfoListData = [];
        state.ldapSyncdata=[];
        state.ldapSyncdataFulFill=false;
        state.alertError = true;
      })

      .addCase(userInfoCountApi.pending, (state, action) => {
        state.userInfoCountLoading = true;
        state.alertError = false;
      })
      .addCase(userInfoCountApi.fulfilled, (state, action) => {
        state.userInfoCountData = action.payload;
        state.userInfoCountLoading = false;
        state.alertError = false;
      })
      .addCase(userInfoCountApi.rejected, (state, action) => {
        state.userInfoCountLoading = false;
        state.userInfoCountData = [];
        state.alertError = true;
      })
      .addCase(getApplication.pending, (state, action) => {
        state.appLoader = true;
        state.applicationList = [];
        state.error = false;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.appLoader = false;
        state.applicationList = action.payload;
        state.error = false;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.appLoader = false;
        state.error = true;
      })     
      // serverInfo List
      .addCase(serverInfoListApi.pending, (state, action) => {
        state.serverInfoListLoading = true;
        state.alertError = false;
      })
      .addCase(serverInfoListApi.fulfilled, (state, action) => {
        state.serverInfoListData = action.payload;
        state.serverInfoListLoading = false;
        state.alertError = false;
      })
      .addCase(serverInfoListApi.rejected, (state, action) => {
        state.serverInfoListLoading = false;
        state.serverInfoListData = [];
        state.alertError = true;
      })

      .addCase(serverInfoCountApi.pending, (state, action) => {
        state.serverInfoCountLoading = true;
        state.alertError = false;
      })
      .addCase(serverInfoCountApi.fulfilled, (state, action) => {
        state.serverInfoCountData = action.payload;
        state.serverInfoCountLoading = false;
        state.alertError = false;
      })
      .addCase(serverInfoCountApi.rejected, (state, action) => {
        state.serverInfoCountLoading = false;
        state.serverInfoCountData = [];
        state.alertError = true;
      })

      // cloudAccount List
      .addCase(cloudAccountListApi.pending, (state, action) => {
        state.cloudAccountListLoading = true;
        state.alertError = false;
      })
      .addCase(cloudAccountListApi.fulfilled, (state, action) => {
        state.cloudAccountListData = action.payload;
        state.cloudAccountListLoading = false;
        state.alertError = false;
      })
      .addCase(cloudAccountListApi.rejected, (state, action) => {
        state.cloudAccountListLoading = false;
        state.cloudAccountListData = [];
        state.alertError = true;
      })

      .addCase(cloudAccountCountApi.pending, (state, action) => {
        state.cloudAccountCountLoading = true;
        state.alertError = false;
      })
      .addCase(cloudAccountCountApi.fulfilled, (state, action) => {
        state.cloudAccountCountData = action.payload;
        state.cloudAccountCountLoading = false;
        state.alertError = false;
      })
      .addCase(cloudAccountCountApi.rejected, (state, action) => {
        state.cloudAccountCountLoading = false;
        state.cloudAccountCountData = [];
        state.alertError = true;
      })
      // osLicenseConfig List
      .addCase(osLicenseConfigListApi.pending, (state, action) => {
        state.osLicenseConfigListLoading = true;
        state.alertError = false;
      })
      .addCase(osLicenseConfigListApi.fulfilled, (state, action) => {
        state.osLicenseConfigListData = action.payload;
        state.osLicenseConfigListLoading = false;
        state.alertError = false;
      })
      .addCase(osLicenseConfigListApi.rejected, (state, action) => {
        state.osLicenseConfigListLoading = false;
        state.osLicenseConfigListData = [];
        state.alertError = true;
      })

      .addCase(osLicenseConfigCountApi.pending, (state, action) => {
        state.osLicenseConfigCountLoading = true;
        state.alertError = false;
      })
      .addCase(osLicenseConfigCountApi.fulfilled, (state, action) => {
        state.osLicenseConfigCountData = action.payload;
        state.osLicenseConfigCountLoading = false;
        state.alertError = false;
      })
      .addCase(osLicenseConfigCountApi.rejected, (state, action) => {
        state.osLicenseConfigCountLoading = false;
        state.osLicenseConfigCountData = [];
        state.alertError = true;
      })

      .addCase(isLdapUserCheckAPI.pending, (state, action) => {
        state.isLdapUserLoading = true;
        state.alertError = false;
      })
      .addCase(isLdapUserCheckAPI.fulfilled, (state, action) => {
        state.isLdapUser = action.payload;
        state.isLdapUserLoading = false;
        state.alertError = false;
      })
      .addCase(isLdapUserCheckAPI.rejected, (state, action) => {
        state.isLdapUserLoading = false;
        state.isLdapUser = [];
        state.alertError = true;
      })

      .addCase(ldapSyncAPI.pending, (state, action) => {
        state.ldapSyncLoading = true;
        state.alertError = false;
      })
      .addCase(ldapSyncAPI.fulfilled, (state, action) => {
        state.ldapSyncdata = action.payload;
        state.ldapSyncdataFulFill = true;
        state.ldapSyncLoading = false;
        state.alertError = false;
      })
      .addCase(ldapSyncAPI.rejected, (state, action) => {
        state.ldapSyncLoading = false;
        state.ldapSyncdata = [];
        state.alertError = true;
      })

      //cloud account test connection
      .addCase(checkCloudAccountsTestConnection.pending, (state, action) => {
        state.cloudAccountTestConnectionLoading = true;
      })
      .addCase(checkCloudAccountsTestConnection.fulfilled, (state, action) => {
        state.cloudAccountTestConnection = action.payload;
        state.cloudAccountTestConnectionLoading = false;
      })
      .addCase(checkCloudAccountsTestConnection.rejected, (state, action) => {
        state.cloudAccountTestConnectionLoading = false;
        state.cloudAccountTestConnection = [];
      })

      .addCase(ImportCSVCloudAccounts.pending, (state) => {
        state.importCSVloading = true; 
        state.alertError = false;
      })
      .addCase(ImportCSVCloudAccounts.fulfilled, (state, action) => {
        state.importCSVloading = false; 
        state.importCSVfullfilled = true; 
        state.importCSVData = action.payload; 
        state.alertError = false;
      })
      .addCase(ImportCSVCloudAccounts.rejected, (state) => {
        state.importCSVloading = false; 
        state.alertError = true;
      })

      .addCase(getSmtpToogle.pending, (state) => {
        state.smtptoggleLoading = true; 
        state.alertError = false;
      })
      .addCase(getSmtpToogle.fulfilled, (state, action) => {
        state.smtpToggleData = action.payload; 
        state.smtptoggleLoading = false;
        state.alertError = false;
      })
      .addCase(getSmtpToogle.rejected, (state) => {
        state.smtptoggleLoading = false;
        state.smtpToggleData = [];
        state.alertError = true;
      });
  },
});

export const { setCmdbId, resetDataSave, resetresponseData,resetldapSyncStatus, setalertError, resetImportCSVData} =
  CMDBSlice.actions;
export default CMDBSlice.reducer;
