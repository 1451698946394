/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../../../../app/commonSlice";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getSoftware = createAsyncThunk(
  "post/getSoftware",
  async (props) => {
    const {payload,id}=props
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {

      const response = await fetch(
        `${proxy}/api/v1/softwareGroup/getListOfSoftware/${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      // dispatch(
      //   getCountAOR({payload:listPayload.payload,cmdbId:listPayload.cmdbId})
      // );
      // dispatch(getListAOR(listPayload));

      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const saveSoftwareGroup = createAsyncThunk("post/saveSoftwareGroup",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/softwareGroup/saveGroupDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(props),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getSoftwareGroupTableListAndCount = createAsyncThunk("get/getSoftwareTableList", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getSoftwareGroupList/${props.pageNo}/${props.limit}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(props.payload),
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getSoftwareGroupTableListDelete = createAsyncThunk("get/getSoftwareGroupTableListDelete", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/deleteGroupByID/${props.id}`, {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getSoftwareGroupIncludedSoftware = createAsyncThunk("get/getSoftwareGroupIncludedSoftware", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/fetchSoftwareListById/${props.rowData.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? { rowData: props.rowData, data: data } : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getOwnersAndDivisions = createAsyncThunk("get/getOwnersAndDivisions", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getOwnersAndDivisions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getByIdSG = createAsyncThunk("get/getByIdSG", async (props) => {
  const {id} = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getGroupDataById/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getSoftwareGroupDivisionCategory = createAsyncThunk("get/getSoftwareGroupDivisionCategory", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getOwnersAndDivisions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

export const getTooltipText= createAsyncThunk("get/getTooltipText", async () => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/softwareGroup/getListOfSoftwareWithPublisher`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "CMTS-API-TOKEN": apiToken,
        USERNAME: username,
      },
    });
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);

const initialState = {
  getSoftwareList: [],
  getSoftwareLoader: false,
  saveData: "",
  saveLoader: false,
  error: false,
  getSoftwareGroupTableListAndCountData: [],
  getSoftwareGroupTableListAndCountLoading: false,
  getSoftwareGroupTableListIdDelete: [],
  getSoftwareGroupTableListIdDeleteLoading: false,
  getSoftwareGroupIncludedSoftwares: [],
  getSoftwareGroupIncludedSoftwaresLoading: false,
  getSoftwareGroupDivisionCategoryData: [],
  getSoftwareGroupDivisionCategoryDataLoading: false,
  getOwnersAndDivisionsList: [],
  getOwnersAndDivisionsLoader: false,
  getByIdSGData:[],
  getByIdSGLoader:false,
  getTooltipData:[],
  getTooltipLoader:false,
}
export const SoftwareGroupSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas = {
        getSoftwareList: [],
        getSoftwareLoader: false,
        saveData: "",
        saveLoader: false,
        error: false,
        getSoftwareGroupTableListIdDelete: [],
        getSoftwareGroupTableListIdDeleteLoading: false,
        getSoftwareGroupIncludedSoftwares: [],
        getSoftwareGroupIncludedSoftwaresLoading: false,
        getSoftwareGroupDivisionCategoryData: [],
        getSoftwareGroupDivisionCategoryDataLoading: false,
        getOwnersAndDivisionsList: [],
        getOwnersAndDivisionsLoader: false,
        getByIdSGData:[],
        getByIdSGLoader:false,
        getTooltipData:[],
        getTooltipLoader:false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSoftware.pending, (state, action) => {
        state.getSoftwareLoader = true;
        state.getSoftwareList = [];
        state.error = false;
      })
      .addCase(getSoftware.fulfilled, (state, action) => {
        state.getSoftwareLoader = false;
        state.getSoftwareList = action.payload;
        state.error = false;
      })
      .addCase(getSoftware.rejected, (state, action) => {
        state.getSoftwareLoader = false;
        state.error = true;
      })
      .addCase(getSoftwareGroupTableListAndCount.pending, (state, action) => {
        state.getSoftwareGroupTableListAndCountData = [],
          state.getSoftwareGroupTableListAndCountLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupTableListAndCount.fulfilled, (state, action) => {
        state.getSoftwareGroupTableListAndCountData = action.payload,
          state.getSoftwareGroupTableListAndCountLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupTableListAndCount.rejected, (state, action) => {
        state.getSoftwareGroupTableListAndCountLoading = false
        state.error = true;
      })
      .addCase(getSoftwareGroupTableListDelete.pending, (state, action) => {
        state.getSoftwareGroupTableListIdDelete = [],
          state.getSoftwareGroupTableListIdDeleteLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupTableListDelete.fulfilled, (state, action) => {
        state.getSoftwareGroupTableListIdDelete = action.payload,
          state.getSoftwareGroupTableListIdDeleteLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupTableListDelete.rejected, (state, action) => {
        state.getSoftwareGroupTableListIdDeleteLoading = false
        state.error = true;
      })
      .addCase(getSoftwareGroupIncludedSoftware.pending, (state, action) => {
        state.getSoftwareGroupIncludedSoftwares = [],
          state.getSoftwareGroupIncludedSoftwaresLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupIncludedSoftware.fulfilled, (state, action) => {
        state.getSoftwareGroupIncludedSoftwares = action.payload,
          state.getSoftwareGroupIncludedSoftwaresLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupIncludedSoftware.rejected, (state, action) => {
        state.getSoftwareGroupIncludedSoftwaresLoading = false
        state.error = true;
      })
      .addCase(getSoftwareGroupDivisionCategory.pending, (state, action) => {
        state.getSoftwareGroupDivisionCategoryData = [],
        state.getSoftwareGroupDivisionCategoryDataLoading = true
        state.error = false;
      })
      .addCase(getSoftwareGroupDivisionCategory.fulfilled, (state, action) => {
        state.getSoftwareGroupDivisionCategoryData = action.payload,
        state.getSoftwareGroupDivisionCategoryDataLoading = false
        state.error = false;
      })
      .addCase(getSoftwareGroupDivisionCategory.rejected, (state, action) => {
        state.getSoftwareGroupDivisionCategoryDataLoading = false
        state.error = true;
      })

      .addCase(saveSoftwareGroup.pending, (state, action) => {
        state.saveLoader = true;
        state.saveData = "";
        state.error = false;
      })
      .addCase(saveSoftwareGroup.fulfilled, (state, action) => {
        state.saveLoader = false;
        state.saveData = action.payload;
        state.error = false;
      })
      .addCase(saveSoftwareGroup.rejected, (state, action) => {
        state.saveLoader = false;
        state.error = true;
      })

      .addCase(getOwnersAndDivisions.pending, (state, action) => {
        state.getOwnersAndDivisionsLoader = true;
        state.getOwnersAndDivisionsList = [];
        state.error = false;
      })
      .addCase(getOwnersAndDivisions.fulfilled, (state, action) => {
        state.getOwnersAndDivisionsLoader = false;
        state.getOwnersAndDivisionsList = action.payload;
        state.error = false;
      })
      .addCase(getOwnersAndDivisions.rejected, (state, action) => {
        state.getOwnersAndDivisionsLoader = false;
        state.error = true;
      })

      .addCase(getByIdSG.pending, (state, action) => {
        state.getByIdSGLoader = true;
        state.getByIdSGData = [];
        state.error = false;
      })
      .addCase(getByIdSG.fulfilled, (state, action) => {
        state.getByIdSGLoader = false;
        state.getByIdSGData = action.payload;
        state.error = false;
      })
      .addCase(getByIdSG.rejected, (state, action) => {
        state.getByIdSGLoader = false;
        state.error = true;
      })

      .addCase(getTooltipText.pending, (state, action) => {
        state.getTooltipLoader = true;
        state.getTooltipData = [];
        state.error = false;
      })
      .addCase(getTooltipText.fulfilled, (state, action) => {
        state.getTooltipLoader = false;
        state.getTooltipData = action.payload;
        state.error = false;
      })
      .addCase(getTooltipText.rejected, (state, action) => {
        state.getTooltipLoader = false;
        state.error = true;
      })
  },
});
export const { resetDataCB, setAlertError } = SoftwareGroupSlice.actions;
export default SoftwareGroupSlice.reducer;
