/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../../../../app/commonSlice";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const saveCBudget = createAsyncThunk(
  "post/saveCB",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, payload ,listPayload} = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudCostBudget/saveOrUpdateCloudBudgetCost/${userName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      dispatch(
        getCountCB({payload:listPayload.payload})
      );
      dispatch(getListCB(listPayload));

      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCountCB = createAsyncThunk(
  "post/getCountCB",
  async (props) => {
      const { payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/cloudCostBudget/getCloudBudgetCostTotalCount`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();

          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getListCB = createAsyncThunk(
  "post/getListCB",
  async (props) => {
      const { pageNumber, itemsPerPage, payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/cloudCostBudget/getCloudBudgetCostListBySearchSortAndPagination/${pageNumber}/${itemsPerPage}`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data)
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getServiceListCB = createAsyncThunk(
  "get/serviceProviderCB",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
    const response = await fetch(
      `${proxy}/api/v1/cloudCostBudget/getCloudServiceProviderList/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
    }
});
export const getAccountIdListCB = createAsyncThunk(
  "get/accountIdCB",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    let resultArray=[];
    try {
    const response = await fetch(
      `${proxy}/api/v1/cloudCostBudget/getAccountIdList/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data)
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
);
export const getByIdCB = createAsyncThunk("get/getByIdCB", async (props) => {
  const id = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
  const response = await fetch(`${proxy}/api/v1/cloudCostBudget/getById/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "CMTS-API-TOKEN": apiToken,
      USERNAME: username,
    },
  });
  handleResponseCode(response);
  const data = await response.json();
  return response.ok ? data : Promise.reject(data)
} catch (error) {
  console.error("Error:", error);
  throw error;
}
}
);
export const deleteCB = createAsyncThunk(
  "post/deleteCB",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id, cmdbId,listPayload} = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudCostBudget/deleteCloudBudgetCost/${id}`,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();

      dispatch(
        getCountCB({payload:listPayload.payload})
      );
      dispatch(getListCB(listPayload));

      return response.ok ? res : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error so the calling code can handle it
    }
  }
);
const initialState={
  dataCB: "",
  serviceListCB: [],
  accountIdListCB: [],
  tableListCB: [],
  getDataCB: [],
  saveLoadingCB: false,
  tableLoadingCB: false,
  getLoadingCB: false,
  getLoadingCB: false,
  deleteDataCB:"",
  deleteLoadingCB:false,
  CountCB:'',
  CountCBLoading:false,
  error : false,
}
export const CBudgetSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas={
        dataCB: "",
        serviceListCB: [],
        accountIdListCB: [],
        tableListCB: [],
        getDataCB: [],
        saveLoadingCB: false,
        tableLoadingCB: false,
        getLoadingCB: false,
        getLoadingCB: false,
        deleteDataCB:"",
        deleteLoadingCB:false,
        CountCB:'',
        CountCBLoading:false,
        error : false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCBudget.pending, (state, action) => {
        state.saveLoadingCB = true;
        state.dataCB = "";
        state.error = false;
      })
      .addCase(saveCBudget.fulfilled, (state, action) => {
        state.saveLoadingCB = false;
        state.dataCB = action.payload;
        state.error = false;
      })
      .addCase(saveCBudget.rejected, (state, action) => {
        state.saveLoadingCB = false;
        state.error = true;
      })
      .addCase(getServiceListCB.pending, (state, action) => {
        state.serviceListCB = [];
        state.error = false;
      })
      .addCase(getServiceListCB.fulfilled, (state, action) => {
        state.serviceListCB = action.payload;
        state.error = false;
      })
      .addCase(getServiceListCB.rejected, (state, action) => {
        state.serviceListCB = [];
        state.error = true;
      })
      .addCase(getAccountIdListCB.pending, (state, action) => {
        state.accountIdListCB =[];
        state.error = false;
      })
      .addCase(getAccountIdListCB.fulfilled, (state, action) => {
        state.accountIdListCB = action.payload;
        state.error = false;
      })
      .addCase(getAccountIdListCB.rejected, (state, action) => {
        state.accountIdListCB =[];
        state.error = true;
      })
      
      .addCase(getListCB.pending, (state, action) => {
        state.tableLoadingCB = true;
        state.error = false;
      })
      .addCase(getListCB.fulfilled, (state, action) => {
        state.tableLoadingCB = false;
        state.tableListCB = action.payload;
        state.error = false;
      })
      .addCase(getListCB.rejected, (state, action) => {
        state.tableLoadingCB = false;
        state.error = true;
      })
      .addCase(getByIdCB.pending, (state, action) => {
        state.getLoadingCB = true;
        state.error = false;
      })
      .addCase(getByIdCB.fulfilled, (state, action) => {
        state.getLoadingCB = false;
        state.getDataCB = action.payload;
        state.error = false;
      })
      .addCase(getByIdCB.rejected, (state, action) => {
        state.getLoadingCB = false;
        state.error = true;
      })
      .addCase(deleteCB.pending, (state, action) => {
        state.deleteLoadingCB = true;
        state.deleteDataCB = "";
        state.error = false;
      })
      .addCase(deleteCB.fulfilled, (state, action) => {
        state.deleteLoadingCB = false;
        state.deleteDataCB = action.payload;
        state.error = false;
      })
      .addCase(deleteCB.rejected, (state, action) => {
        state.deleteLoadingCB = false;
        state.error = true;
      })
      .addCase(getCountCB.pending, (state, action) => {
        state.CountCBLoading = true;
        state.error = false;
      })
      .addCase(getCountCB.fulfilled, (state, action) => {
        state.CountCBLoading = false;
        state.CountCB = action.payload;
        state.error = false;
      })
      .addCase(getCountCB.rejected, (state, action) => {
        state.CountCBLoading = false;
        state.error = true;
      })
  },
});
export const { resetDataCB, setAlertError } = CBudgetSlice.actions;
export default CBudgetSlice.reducer;
