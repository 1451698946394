import React, { useState, useEffect } from "react";
import { Button, Offcanvas, Spinner } from "react-bootstrap";
import { IoChevronDown, IoChevronUp, IoReturnDownBack } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import {
  getMessageRead,
  getNotificationData,
} from "../../features/homePage/HomeApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Spinners from "./Spinner";
import { decrypt } from "../utilities/helpers";

const NotificationOffCanvas = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const { getNotificationDatainfo, getNotificationDataLoading } = useSelector(
    (state) => state.HomeApiCall
  );
  const [show, setShow] = useState(true);
  const [clearnotification, setclearnotification] = useState([]);
  const [notificationStates, setNotificationStates] = useState([]);
  const [clickedNotifications, setClickedNotifications] = useState([]);
  const [isloading, setisloading] = useState(false);
  const { userName, userRole } = useSelector(
    (state) => state.common.loggedUser
  );
  const encoded = decrypt(userName)
  const toggleNotificationView = (index, notificationId) => {
    setNotificationStates((prevStates) => {
      const newState = [...prevStates];
      newState[index] = !newState[index];
      return newState;
    });

    if (!clickedNotifications.includes(notificationId)) {
      setClickedNotifications((prevClickedNotifications) => [
        ...prevClickedNotifications,
        notificationId,
      ]);
    }
  };
  useEffect(() => {
    if(!isEmpty(props.notificationData)){
    setisloading(true);
    setIsDisabled(false);
    const payload = {
      userId: encoded,
      role: userRole,
    };
    dispatch(getNotificationData({ payload: payload })).then((data) => {
      setclearnotification(data.payload);
      setisloading(false);
    });
  }
  }, []);
  const handleClearAll = () => {
    const notificationids = getNotificationDatainfo.map((item, index) => {
      return String(item.id);
    });
    dispatch(getMessageRead({ payload: notificationids || ["0"]}));
    setclearnotification([]);
    setIsDisabled(true);
  };
  const onNavigate = (e, id, dashboard) => {
    e.preventDefault();
    dispatch(getMessageRead({ payload: [String(id)] || ["0"] }));
    if (dashboard === "SecurityHub") {
      navigate("/gnc/compliance/securityHub/securityHubFindingsLandings");
      props.onClose();
    } else if (dashboard === "License User") {
      navigate("/softwareLicenseManagement/Summary");
      props.onClose();
    } else if (dashboard === "CISO") {
      navigate("/gnc/CISOInsights/securityHubFindings");
      props.onClose();
    } else if (dashboard === "CMDB Configuration") {
      navigate("/configuration/cmdb/applicationConfiguration");
      props.onClose();
    } else {
      navigate("/home");
      props.onClose();
    }
  };
  const handleClearNotification = (notificationId) => {
    const updatedNotifications = clearnotification.filter(
      (notification) => notification.id !== notificationId
    );
    setclearnotification(updatedNotifications);

    dispatch(getMessageRead({ payload: [String(notificationId)] || ["0"] }));
  };

  const timining = new Date();
  const formattedDate = timining.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return (
    <>
      <div className="offcanvas-backdrop NotificationAlign">
        <Offcanvas placement="end" className={"Notificationdiv"} show={show}>
          <div>
            <span
              className="NotificationClose"
              onClick={() => {
                setShow(false);
                props.onClose();
              }}
            >
              x
            </span>
          </div>
          <div className="Notificationpage">
            <h3 className="NotificationTitle">Notifications</h3>
            <span>
              <Button variant="link" onClick={handleClearAll} disabled={isDisabled}>
                Clear All
              </Button>
            </span>
          </div>

          {/* Mapping through notificationData to render NotificationBoxes */}
          {isloading ? (
            <Spinners />
          ) : !isEmpty(clearnotification) ? (
            clearnotification.map((notification, index) => (
              <div key={index}>
                <div
                  className={`NotificationBoxes mb-3 ${
                    clickedNotifications.includes(notification.dashboard)
                      ? ""
                      : "NotopenedNF"
                  }`}
                >
                  <div
                    className="NotificationSubject"
                    onClick={(e) =>
                      onNavigate(e, notification.id, notification.dashboard)
                    }
                  >
                    <span>
                      <h4  className="NotificationSubject">{notification.dashboard}</h4>
                      <p
                        className={`${
                          notificationStates[index] ? "Notificationexpanded" : "Notificationcollapsed"
                        }`}
                      >
                        {notification.messageContent}
                      </p>
                    </span>
                    <span className="Notificationicons">
                    <RxCross2
                      onClick={() => handleClearNotification(notification.id)}
                    />
                    </span>
                  </div>
                  <div className="NotificationAlignment">
                    <p className="NotificationTime mb-0">
                      {formattedDate || "No timestamp"}
                    </p>
                    <span className="Notificationicons">
                      {notificationStates[index] ? 
                        <IoChevronUp
                          onClick={() =>
                            toggleNotificationView(index, notification.id)
                          }
                        />
                      :
                        <IoChevronDown
                          onClick={() =>
                            toggleNotificationView(index, notification.id)
                          }
                        />
                      }
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No notification found</div>
          )}
        </Offcanvas>
      </div>
    </>
  );
};

export default NotificationOffCanvas;
