import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getAuditList = createAsyncThunk(
  "get/getData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { page, count, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/auditLog/list/${page}/${count}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getAuditLogCount = createAsyncThunk(
    "get/getDataCount",
    async (props) => {
      const { payload } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      try {
        const response = await fetch(
          `${proxy}/api/v1/auditLog/count`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
            body: JSON.stringify(payload),
          }
        );
        handleResponseCode(response);
        const data = await response.json();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );

  export const ExportCSV = createAsyncThunk(
    "get/ExportCSV",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      try {
        const response = await fetch(
          `${proxy}/api/v1/auditLog/download/auditLog`,
          {
            method: 'GET',
            headers: {
              Accept: '*/*',
              "Content-Type": "application/json",
              USERNAME: User,
              "CMTS-API-TOKEN": Apitoken,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.blob();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );

const AuditLogSlice = createSlice({
  name: "AuditLogSlice",
  initialState: {
    getAuditListData : [],
    auditCount : [],
    error : "",
    auditLoading : false,
    auditcountLoading : false,
    CSVDetails : [],
    CSVloading : false,
    apierror : false,
  },
  reducers: {
    AuditLogSliceReset: () => {
        return initialState;
    },
    setErrorAlert: {
      reducer(state, action) {
        state.apierror = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditList.pending, (state, action) => {
        state.auditLoading = true;
        state.apierror = false;
      })
      .addCase(getAuditList.fulfilled, (state, action) => {
        state.getAuditListData = action.payload;
        state.auditLoading = false;
        state.apierror = false;
      })
      .addCase(getAuditList.rejected, (state, action) => {
        state.auditLoading = false;
        state.getAuditListData = [];
        state.error = "Something went wrong";
        state.apierror = true;
      })

      .addCase(getAuditLogCount.pending, (state, action) => {
        state.auditcountLoading = true;
        state.apierror = false;
      })
      .addCase(getAuditLogCount.fulfilled, (state, action) => {
        state.auditCount = action.payload;
        state.auditcountLoading = false;
        state.apierror = false;
      })
      .addCase(getAuditLogCount.rejected, (state, action) => {
        state.auditcountLoading = false;
        state.auditCount = [];
        state.error = "Something went wrong";
        state.apierror = true;
      })

      //export csv
      .addCase(ExportCSV.pending, (state, action) => {
        state.CSVloading = true;
        state.apierror = false;
      })
      .addCase(ExportCSV.fulfilled, (state, action) => {
        state.CSVDetails = action.payload;
        state.CSVloading = false;
        state.apierror = false;
      })
      .addCase(ExportCSV.rejected, (state, action) => {
        state.CSVloading = false;
        state.apierror = true;
      })
  },
});

export const { AuditLogSliceReset, setErrorAlert } =
AuditLogSlice.actions;
export default AuditLogSlice.reducer;
