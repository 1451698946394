import { DATE_FORMAT } from "../../app/utilities/constants";
import moment from "moment";
import { replace, zipObject } from "lodash";
import Color from "color";
import React, { Component, useRef } from 'react';
import { useDispatch } from "react-redux";
import { setCommonAlert } from "../../app/commonSlice";
import _ from "lodash";
const initialStartDate = moment().startOf("month");
const initialEndDate = moment();

// const initialStartDate = moment().format('MM/DD/YYYY');
// const initialEndDate = moment().format('MM/DD/YYYY');

// const initialStartDate = moment().format('MM/DD/YYYY');
// const initialEndDate = moment().format('MM/DD/YYYY');

// const convertedStartDate = moment(initialStartDate, 'MM/DD/YYYY').format('DD/MM/YYYY');
// const convertedEndDate = moment(initialEndDate, 'MM/DD/YYYY').format('DD/MM/YYYY');

export const getDefaultSelectedDate = () => ({
  initialStartDate,
  initialEndDate,
});

export const getMonthStartAndEndDates = (fromdate, todate) => {
  const getMonthStartDate = (date) => {
    const d = new Date(date * 1000);
    d.setDate(1); // Set the date to 1st of the month
    d.setHours(0, 0, 0, 0); // Set the time to midnight
    return Math.floor(d.getTime() / 1000); // Return the epoch timestamp
  };

  const getLastDateOfMonth = (date) => {
    const d = new Date(date * 1000);
    const lastDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    lastDate.setHours(23, 59, 59, 999); // Set the time to the end of the day
    return Math.floor(lastDate.getTime() / 1000); // Return the epoch timestamp
  };

  const currentTimestamp = Math.floor(Date.now() / 1000); // Current epoch timestamp
  const fromdateMonthStart = getMonthStartDate(fromdate);

  let todateMonthEnd;
  if (currentTimestamp > getLastDateOfMonth(fromdate)) {
    // Current date has passed the last date of the month
    todateMonthEnd = getLastDateOfMonth(todate);
  } else {
    // Current month is not over yet
    todateMonthEnd = currentTimestamp;
  }

  return {
    fromDt: fromdateMonthStart,
    toDt: todateMonthEnd
  };
};
const defaultTools = ["jira", "confluence", "jenkins"];
export const getDefaultTools = () => ({
  defaultTools,
});
export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return string; // Return the input as is
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export const getDefaultIncidentClass = (label) => {
  const classNames = {
    Performance: "managecol dark-blue-border",
    Availability: "managecol blue-border",
    Network: "managecol red-border",
    Others: "managecol purple-border",
    Critical: "managecol red-border",
    Major: "managecol major-border",
    Minor: "managecol blue-border",
    Normal: "managecol dark-blue-border",
    Standard: "managecol blue-border",
    Emergency: "managecol red-border",
    Low: "managecol prLow-border",
    Medium: "managecol major-border",
    High: "managecol prHigh-border",
    "Very High": "managecol pink-border",
  };
  return classNames[label];
};
export const getMetricTypeMappedCount = (type = {}, mapping) => {
  let count = 0;
  Object.keys(type).map((key) => {
    if (mapping.includes(key)) {
      count += type[key];
    }
  });
  return count;
};
export const statusOrder = (array, order, key) => {
  const orderArray = []
  order.map((name) => {
    array.map((item) => {
      if (name === item.name) orderArray.push(item)
    })
  })
  return orderArray
};
export const getMetricMatchingStatus = (type, mapping) => {
  let status = {
    isMatching: false,
    matchedKey: "",
  };
  if (typeof type === "object") {
    Object.keys(type).map((key) => {
      if (mapping.includes(key)) {
        status = {
          isMatching: true,
          matchedKey: key,
        };
      }
    });
    return status
  }
  if (mapping.includes(type)) {
    status = {
      isMatching: true,
      matchedKey: type,
    };
  }
  return status;
};
export const getSortedArr = (arr) => {
  const keyValuePair = Object.entries(arr);
  const sortedArr = keyValuePair.sort(([, a], [, b]) => b - a);
  const convertedObject = sortedArr.map((data) =>
    zipObject(["project", "count"], data)
  );
  return convertedObject;
};

export const GetSortOrder = (prop) => {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};
export const convertEpochToDate = (givenDate) => {
  if (givenDate === "null") {
    return '-';
  }
  if (givenDate === 0) {
    return '-';
  }
  const dateObj = new Date(convertEpochTimeToMilliseconds(givenDate));

  const day = dateObj.toLocaleString('en-US', { day: '2-digit' });
  const month = dateObj.toLocaleString('en-US', { month: '2-digit' });
  const year = dateObj.toLocaleString('en-US', { year: 'numeric' });

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  if (hours >= 24) {
    hours -= 24;
  }

  return `${day}/${month}/${year}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
export const convertEpochToISO8601 = (epochMillis) => {
  const date = new Date(epochMillis); // convert milliseconds to a Date object
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00`;
}

export function convertToEpoch(dateString) {
  const epochTime = Date.parse(dateString);
  return epochTime / 1000; // Convert milliseconds to seconds
}

function convertEpochTimeToMilliseconds(epochTime) {
  if (epochTime > 1e12) {
    return epochTime;
  } else {
    const epochTimeInMilliseconds = epochTime * 1000;
    return epochTimeInMilliseconds;
  }
}
// export const convertEpochToDate=(givenDate)=>{
//   return(new Date(convertEpochTimeToMilliseconds(givenDate)).toLocaleString('en-US',{
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: false
//   }));
// }
// function convertEpochTimeToMilliseconds(epochTime) {
//   if (epochTime > 1e12) {
//     return epochTime;
//   } else {
//     const epochTimeInMilliseconds = epochTime * 1000;
//     return epochTimeInMilliseconds;
//   }
// }
export const getMonthNumber = (month) => {
  const monthOptions = [
    { name: "Jan", value: "01" },
    { name: "Feb", value: "02" },
    { name: "Mar", value: "03" },
    { name: "Apr", value: "04" },
    { name: "May", value: "05" },
    { name: "Jun", value: "06" },
    { name: "Jul", value: "07" },
    { name: "Aug", value: "08" },
    { name: "Sep", value: "09" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" }
  ];
  const selectedMonthObj = monthOptions.find(
    (option) => option.name === month
  );
  return selectedMonthObj.value;
}
export const validateEmail = (mail) => {
  var regex =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(mail)) {
    return false;
  }
  return true;
};

export const getDates = (startDate, stopDate, dateFormat = "DD MM YYYY") => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(moment(new Date(currentDate)).format(dateFormat));
    currentDate = currentDate.add(1, "days");
  }
  return dateArray;
};
export const getImage = (toolnameimage) => {
  try {
    // eslint-disable-next-line no-undef
    return require(`../../assets/images/tools/${toolnameimage}.png`);
  } catch {
    // eslint-disable-next-line no-undef
    return require(`../../assets/images/tools/toolsDefault.png`);
  }
};
export const validateProject = (value) => {
  if (/^[a-zA-Z][a-zA-Z0-9]+$/.test(value)) {
    return true;
  }
  return false;
};
export function validateAlphaNumericSpace(value)
{
  if (/^[a-zA-Z][a-zA-Z0-9 ]+$/.test(value)) {
    return true;
  }
  return false;
}

export function createLocalTime(date) {
  const localTimeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // Get the offset in milliseconds
  const localTimeZoneEpochValue = date - localTimeZoneOffset;
  return localTimeZoneEpochValue;
}
export const getPageToRequest = (listCount, pageLimit, currentPage, action) => {
  const remainingCount = action === "add" ? listCount + 1 : listCount - 1;
  const shouldRequestNextPage = action === "add" ? remainingCount % pageLimit === 0 : currentPage > 0 && remainingCount % pageLimit === 0;

  if (shouldRequestNextPage && action === "delete") {
    return currentPage > 0 ? currentPage - 1 : 0;
  } else if (shouldRequestNextPage && action === "add") {
    return currentPage + 1;
  } else {
    return currentPage;
  }
};

export function formatDateYear(givenDate) {
  if (givenDate === "null") {
    return '-';
  }

  const dateObj = new Date(convertEpochTimeToMilliseconds(givenDate));

  const day = dateObj.toLocaleString('en-US', { day: '2-digit' });
  const month = dateObj.toLocaleString('en-US', { month: '2-digit' });
  const year = dateObj.toLocaleString('en-US', { year: 'numeric' });


  return `${day}/${month}/${year}`;
}

export function ISOformatDate(inputTime) {
  const date = new Date(inputTime);
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedDate;
}

export function arrayDataName(applicationName) {
  if (Array.isArray(applicationName)) {
    return (
      <td className="nav">
        {applicationName.map((item, index) => (
          <td key={index}>
            {item}
          </td>
        ))}
      </td>
    )
  } else {
    return (
      <td>
        {applicationName}
        </td>
    );
  }
}
export function graphEpochToDate(epoch) {
  const date = new Date(epoch * 1000); // Convert epoch to milliseconds
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}

export const PaginationInfo = ({ currentPage, pageSize, listCount }) => {
  const startIndex = currentPage * pageSize + 1;
  const endIndex = Math.min((currentPage + 1) * pageSize, listCount);

  return (
    <div className="pagination-info d-flex align-items-center">
      <span className="me-2">
        Showing <strong>{startIndex}</strong> to <strong>{endIndex}</strong> of <strong>{listCount}</strong> records
      </span>
    </div>
  );
};

export const sortMonth = (months) => {
  const monthArr = {
      "Jan": 0,
      "Feb": 1,
      "Mar": 2,
      "Apr": 3,
      "May": 4,
      "Jun": 5,
      "Jul": 6,
      "Aug": 7,
      "Sep": 8,
      "Oct": 9,
      "Nov": 10,
      "Dec": 11
  };
  const sortedMonths = months.sort((a, b) => {
    const yearA = parseInt(a.split(' ')[1], 10);
    const yearB = parseInt(b.split(' ')[1], 10);

    if (yearA !== yearB) {
        return yearA - yearB;
    }

    const monthA = a.split(' ')[0];
    const monthB = b.split(' ')[0];
    return monthArr[monthA] - monthArr[monthB];
});
    return sortedMonths;
}

export function arrayListName(data) {
  return (
    <ul>
      {data.map((item, index) => (
        <li key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
}

export function toFixedAndZero(data) {
  if (data == 0) {
    return 0
  } else {
    return data?.toFixed(2)
  }
}

export const convertColorFormat = (color, toFormat) => {

  //please refere this link https://www.codemotion.com/magazine/frontend/javascript/the-best-color-manipulation-library-in-javascript/
  
  const colorObject = Color(color);
  let convertedColor;

  try {
      switch (toFormat) {
          case 'rgb':
              convertedColor = colorObject.rgb().toString();
              break;
          case 'hsl':
              convertedColor = colorObject.hsl().toString();
              break;
          case 'Hwb':
              convertedColor = colorObject.hwb().toString();
              break;
          case 'Cmyk':
              convertedColor = colorObject.cmyk().toString();
              break;
          case 'hex':
              convertedColor = colorObject.hex().toString();
              break;
          case 'xyz':
              convertedColor = colorObject.xyz().toString();
              break;
          case 'lab':
              convertedColor = colorObject.lab().toString();
              break;
          case 'lch':
              convertedColor = colorObject.lch().toString();
              break;
          case 'ansi16':
              convertedColor = colorObject.ansi16().toString();
              break;
          case 'ansi256':
              convertedColor = colorObject.ansi256().toString();
              break;
          case 'hcg':
              convertedColor = colorObject.hcg().toString();
              break;
          case 'apple':
              convertedColor = colorObject.apple().toString();
              break;
          default:
              console.error('Invalid target color format');
              return color;
      }
  } catch (error) {
      console.error('Error converting color format:', error.message);
      return color; 
  }

  return convertedColor;
};
 
export const newShade = (hexColor, magnitude) => {
  const convertedColor = convertColorFormat(hexColor, 'hex');
  let hexColorChange = convertedColor.replace(`#`, ``);

  if (hexColorChange.length === 6) {
    const decimalColor = parseInt(hexColorChange, 16);

    let r = (decimalColor >> 16) + magnitude;
    let g = (decimalColor & 0x0000ff) + magnitude;
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;

    // Clamp RGB values to ensure they stay within the 0–255 range
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    // Add bias towards white by ensuring higher values for R, G, B
    const whiteBias = 30; // Increase this value to further emphasize white
    r = Math.min(255, r + whiteBias);
    g = Math.min(255, g + whiteBias);
    b = Math.min(255, b + whiteBias);

    return `#${((1 << 24) + (r << 16) + (b << 8) + g).toString(16).slice(1).toUpperCase()}`;
  } else {
    return hexColorChange;
  }
};



///
export const getFirstAndLastDate = (monthYear) => {
  const [month, year] = monthYear.split(' ');

  const dateFormat = `${month} ${year}`;

  const firstdate = moment(dateFormat, 'MMM YYYY').startOf('month').format('DD-MM-YYYY');
  const lastdate = moment(dateFormat, 'MMM YYYY').endOf('month').format('DD-MM-YYYY');
  return { firstdate, lastdate };
}
export const  monthFind = (givenDate) => {
  const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  var date = new Date((givenDate)*1000+1);
  let mon = months[date.getMonth()];
  return mon;
}
export const  convertToEpochStart = (selectedFilters,dateString) => {
  const fromDate = _.get(selectedFilters, 'selectedDate.startDate', null) / 1000;
  const fromDateFormatted = moment.unix(fromDate).format('DD-MM-YYYY');

  const epochTime = moment(dateString, 'DD-MM-YYYY').valueOf();

  const dateStringMonth = moment(dateString, 'DD-MM-YYYY').format('MM');
  const fromDateFormattedMonth = moment(fromDateFormatted, 'DD-MM-YYYY').format('MM');

  if (dateStringMonth === fromDateFormattedMonth) {
      return fromDate;
  } else {
      return epochTime / 1000;
  }
}

export const  convertToEpochlast = (selectedFilters,dateString) => {
  const toDate = _.get(selectedFilters, 'selectedDate.endDate', null) / 1000;
  const toDateFormatted = moment.unix(toDate).format('DD-MM-YYYY');

  const dateStringMonth = moment(dateString, 'DD-MM-YYYY').format('MM');
  const toDateFormattedMonth = moment(toDateFormatted, 'DD-MM-YYYY').format('MM');

  const adjustedDateString = dateString + ' 11:59:59 PM';
  const epochTime = moment(adjustedDateString, 'DD-MM-YYYY hh:mm:ss A').valueOf();

  if (dateStringMonth === toDateFormattedMonth) {
    return toDate;
  } else {
    return epochTime / 1000;
  }
}

export const monthTrim = (monthYear) => {
  const convertEpochTimeToMilliseconds = (epochTime) => {
    return parseInt(epochTime);
  };

  if (typeof monthYear === 'number' || /^\d+$/.test(monthYear)) {
    const date = new Date(convertEpochTimeToMilliseconds(monthYear));
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const trimmedMonth = `${months[monthIndex].slice(0, 3)} ${day}, ${year}`;
    return trimmedMonth;
  } else {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [monthStr, yearStr] = monthYear.split(/[,/-\s]/);
    const monthIndex = months.findIndex(month => month.toLowerCase() === monthStr.toLowerCase());
    if (monthIndex !== -1) {
      const trimmedMonth = `${months[monthIndex]} ${yearStr.trim()}`;
      return trimmedMonth;
    } else {
      return "Invalid format";
    }
  }
}

export const convertToCustomFormat = (givenDate) => {
  const slitTime = givenDate.split(',')
  const datePart = slitTime[0];
  const parts = datePart.split('/');
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const monthAbbreviation = monthNames[parseInt(month) - 1];

  return `${monthAbbreviation}-${year}-${day}`;
};

export const convertEpochDate = (epoch) => {  //01-12-2023
  const date = new Date(epoch * 1000);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  
  return formattedDate;
};
export const severityFiltersmMap = (Severity) => {
  if (Array.isArray(Severity)) {
    const severityFilters = Severity.map((severity, index) => ({
      label: severity,
      value: severity,
      id: index + 1
    }));
    return severityFilters;
  }
  
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

 export const formatDatePayload = (epoch) => {//formate will be 2024-02-01
  const date = new Date(epoch); 
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if needed
  const day = ('0' + date.getDate()).slice(-2); // Adding leading zero if needed
  return `${year}-${month}-${day}`;
};

//Common Alert
export const useCommonAlert = () => {
  // this is the way to call the function
  // callCommonAlert("error","Something went wrong. Please try again.");
  // hideCommonAlert();
  //type =success,warning,error


  const dispatch = useDispatch();
  const alertTimeoutRef = useRef(null);

  const callCommonAlert = (type,msg) => {
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }
    dispatch(setCommonAlert({
      openAlert: true,
      alertType: type,
      alertMessage: msg,
    }));
  };

  const hideCommonAlert = (duration = 5000) => {
    alertTimeoutRef.current = setTimeout(() => {
      dispatch(setCommonAlert({
        openAlert: false,
        alertType: "",
        alertMessage: "",
      }));
      alertTimeoutRef.current = null; // Reset the ref
    }, duration);
  };

  return {callCommonAlert, hideCommonAlert};
};

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }
 
  render() {
    if (this.state.hasError) {
      // You can render a fallback UI here
      return <div className="NodataAvailableVMTDD NodataAvailable">Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}
export function formatDate(dateString) { 
  const [month, year] = dateString.split('/');
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${monthName} ${year}`;
}


export function getMonnthyearasNum(month) { //June 2024  to  2024-6
 const [monthName, year] = month.split(" ");
 const monthNumber = getMonthNumber(monthName);
 const formattedMonth = `${monthNumber}/${year}`;
 return formattedMonth;
}
export const convertEpochToDate_withouttime = (epoch) => {
  const date = new Date(epoch);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export const restrictDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};
export function generateMonthArray(firstMonth, lastMonth) { // month array finder
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let [startMonth, startYear] = firstMonth.split(" ");
  let [endMonth, endYear] = lastMonth.split(" ");

  startYear = parseInt(startYear, 10);
  endYear = parseInt(endYear, 10);

  let startMonthIndex = monthNames.indexOf(startMonth);
  let endMonthIndex = monthNames.indexOf(endMonth);

  let monthsArray = [];

  while (startYear < endYear || (startYear === endYear && startMonthIndex <= endMonthIndex)) {
      monthsArray.push(`${monthNames[startMonthIndex]} ${startYear}`);

      startMonthIndex++;

      if (startMonthIndex === 12) {
          startMonthIndex = 0;
          startYear++;
      }
  }

  return monthsArray;
}

//$ convertion 
export const formatAsDollar = (value) => {
  // Use Intl.NumberFormat for number formatting
  const formattedValue = new Intl.NumberFormat("en-SG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return `S$${formattedValue}`;
};
// Function to get the current month and year
export const getCurrentMonthYear = () => {
  const date = new Date();
  const options = { year: '2-digit', month: 'short' };
  return date.toLocaleDateString('en-US', options);
};

// Function to get the previous month and year
export const getPreviousMonthYear = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1); // Set to previous month
  const options = { year: '2-digit', month: 'short' };
  
  // Get the locale date string and replace the space with a hyphen
  return date.toLocaleDateString('en-US', options).replace(' ', '-');
};


export const getCurrentDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad it to 2 digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad it
  const year = date.getFullYear(); // Get the full year
  
  return `${day}-${month}-${year}`;
};

export const formatAsDollarYTD = (value) => {
  const formatAscost = (value) => {
    if (value >= 1000000000) {
        return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
    } else {
        return parseInt(value).toFixed(2); //formatted as a number
    }
  };
  const cost = formatAscost(value);
  // If cost is already formatted as 'B', 'M', or 'K', skip additional formatting
  if (typeof cost === 'string' && /[BMK]$/.test(cost)) {
    return `S$${cost}`;
  }
  const formattedValue = new Intl.NumberFormat("en-SG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return `S$${formattedValue}`;
};
export const getFiscalYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // 0 for January, 11 for December

  if (currentMonth >= 3) {
    return `FY ${currentYear % 100}`;
  } else {
    return `FY ${(currentYear - 1) % 100}`;
  }
};

export const formatDateSummary = () => {
  const date = new Date();
  date.setMonth(date.getMonth()); // Set to current month
  const options = { year: '2-digit', month: 'short' };
 
  // Get the locale date string and replace the space with a hyphen
  return date.toLocaleDateString('en-US', options).replace(' ', '-');
};

export const convertEpochtoyearDate = (epoch) => {  //2023-12-01
  const date = new Date(epoch * 1000);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export function convertDateToLocalISOString(dateString) {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
      throw new RangeError("Invalid date value provided");
  }
  const date = new Date(dateString);

  // Extract UTC components of the date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(0).padStart(2, '0');
  const minutes = String(0).padStart(2, '0');
  const seconds = String(0).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  } else {
      return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};
