import React from 'react';
import { edit } from '../../assets/images';

export const RoleConfig = (pageName, action, role) => {

    const roleJson = [
        {
            pageName: "Home",
            complianceScore : ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"],
        },
        {
            pageName: "CMDB",
            add: ["Operation","Group Head"],
            edit: ["Operation"],
            delete: ["Operation","Group Head"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
            configure: ["Operation","Group Head","Department Head","Division Head",
                    "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
        },
        {
            pageName: "AOR Details",
            add: ["Operation","Finance Analyst","Application Owner"],
            edit: ["Operation","Finance Analyst","Application Owner"],
            delete: ["Operation"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
        },
        {
            pageName: "Budget Details",
            add: ["Operation","Finance Analyst","Application Owner"],
            edit: ["Operation","Finance Analyst","Application Owner"],
            delete: ["Operation"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
        },
        {
            pageName: "Go Live & Alert Configuration",
            edit: ["Operation","Finance Analyst","Application Owner"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
        },
        {
            pageName: "Master List - Cloud Accounts",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            import:["Operation"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst","Ciso",
                "Soc Lead","Compliance Lead"],
        },
        {
            pageName: "Cloud Accounts",
            add: ["Operation", "Application Owner"],
            delete: ["Operation", "Application Owner"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst","Ciso",
                "Soc Lead","Compliance Lead"],
        },
        {
            pageName: "Devices",
            add: ["Operation", "Application Owner"],
            delete: ["Operation", "Application Owner"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst","Ciso",
                "Soc Lead","Compliance Lead"],
        },
        {
            pageName: "USD to SGD Currency Conversion",
            add: ["Operation"],
            edit: ["Operation"],
            view: ["Operation","Group Head","Department Head","Division Head",
                "Application Owner","Business Owner","Finance Admin","Finance Analyst"],
        },
        {
            pageName:"Costing Calculation",
            edit:["Operation"],
        },
        {
            pageName: "User Management",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            status: ["Operation"],
            view: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"]
        },
        {
            pageName: "Role Mapping",
            edit: ["Operation"],
            view: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"]
        },
        {
            pageName: "Ciso Insights",
            "approve/reject": ["Ciso"],
            view: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"],
        },
        {
            pageName: "Security Hub Findings",
            "request": ["Operation", "Compliance Lead"],
            view: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"],
        },
        {
            pageName: "Audit Log",
            "download": ["Operation"],
            view: ["Operation","Division Head", "Application Owner"],
        },
        {
            pageName: "Scedulars - Data Import",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            status: ["Operation"],
            view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName: "Scedulars - Email",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName: "Tools",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName: "Business Info",
            view : ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"],
            RollAlocation : ["Operation"],
        },
        {
            pageName: "Software List",
            add: ["Operation"],
            edit: ["Operation"],
            delete: ["Operation"],
            // view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName: "Software Group",
            add: ["Operation","License Group Owner"],
            edit: ["Operation","License Group Owner"],
            delete: ["Operation","License Group Owner"],
            // view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName : "License Management",
            add: ["Operation","License Group Owner"],
            edit: ["Operation","License Group Owner"],
            delete: ["Operation","License Group Owner"],
            attest_Validate: ['Division Head'],
            consumed_edit : ["Operation","License Group Owner"],
            summary_details : ["Operation", "Division Head", "License Group Owner"]
        },
        {
            pageName: "ESX",
            add: ["Operation","License Group Owner"],
            edit: ["Operation","License Group Owner"],
            delete: ["Operation","License Group Owner"],
            // view: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"],
        },
        {
            pageName: "Log Approver Insights",
            "approve/reject": ["Division Head", "Log Approver"],
            view: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"],
        },
        {
            pageName: "Log Reviewer Insight",
            "approve/reject": ["Division Head", "Log Approver"],
            view: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"],
        },
    ];

    // const page = roleJson.find(item => item.pageName === pageName);
    // if (page && page[action]) {
    //     return page[action].includes(role);
    // }
    const page = roleJson.find(item => item.pageName === pageName);
    if (page && page[action]) {
        if (Array.isArray(role)) {
            return role.some(r => page[action].includes(r));
        } else {
            return page[action].includes(role);
        }
    }
    return false;
};
export const filterDataHeader = (dataheader, headerName, moduleName, userRole, permissions) => {
    return dataheader.filter((header) => {
        if (header.Header === headerName) {
        return permissions.some((permission) => RoleConfig(moduleName, permission, userRole));
        }
        return true;
    });
}

export const navigationPrevilages = (routepath, userRole) => {
    const allowedRoles = [
        // Home
        { path: "home", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"]  },
        { path: "home/BusinessInfoConfig", roles: ["Operation"] },
        { path: "home/:appName/DeviceConfig", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
        { path: "home/:appName/accountIDs", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },

        // Compliance
        { path: "gnc/compliance/securityHub/securityHubFindingsLandings", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
        { path: "gnc/compliance/CloudScapeFindings", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },

        // Ciso Insights
        { path: "gnc/CISOInsights/securityHubFindings", roles: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"] },
        { path: "gnc/CISOInsights/CloudScapeFindings", roles: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"] },

        // Cloud Cost Insights
        { path: "cloudCost/Insights", roles: ["Operation","Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "cloudCost/CostOverview", roles: ["Operation","Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },

        // License Management
        { path: "softwareLicenseManagement/Summary", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
        { path: "/softwareLicenseManagement/licenseManagement/licenseconfigpage", roles: ["Operation"] },
        { path: "softwareLicenseManagement/licenseManagement", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
        { path: "softwareLicenseManagement/ESXGroup", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },

        //SIRAR Management
        { path: "Sirar", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
        { path: "Sirar/logApproverInsights", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
        { path: "Sirar/logReviewerInsights", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },

        // CMDB Master pages
        { path: "configuration/cmdb/BusinessInfoConfig", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"]  },
        { path: "configuration/cmdb/BusinessInfoConfig/roleAllocation", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/masterlist/UserInfoConfig", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/masterlist/Devices", roles: ["Operation","Application Owner"] },
        { path: "softwareLicenseManagement/softwareGroup", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
        { path: "configuration/cmdb/masterlist/cloudaccount", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "softwareLicenseManagement/SoftwareList", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },

        // CMDB main and child Pages
        { path: "configuration/cmdb", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/:appName/DeviceConfig", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/:appName/accountIDs", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/applicationConfiguration", roles: ["Operation", "Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/cmdb/Devices/licenseconfigpage", roles: ["Operation","Application Owner"] },

        // Settings
        { path: "configuration/settings/CloudCost", roles: ["Operation", "Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/settings/Schedulers", roles: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/settings/tools", roles: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/settings/PIIDataConfiguration", roles: ["Operation"] },

        // administration
        { path: "configuration/administration/directoryConfiguration", roles: ["Operation"] },
        { path: "configuration/administration/smtpConfig", roles: ["Operation"] },
        { path: "configuration/administration/licenseConfig", roles: ["Operation"] },
        { path: "configuration/administration/userManagement", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
        { path: "configuration/administration/loggingConfig", roles: ["Operation"] },
        { path: "configuration/auditLog", roles: ["Operation"] },
        { path: "/configuration/administration/userManagement/rolemapping", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
        { path: "configuration/administration/loggingConfig", roles: ["Operation"] },
        { path: "configuration/auditLog", roles: ["Operation"] },
    ];

    const allowedRoute = allowedRoles.find(route => route.path === routepath);
    const hasAccess = userRole === "" ? true : allowedRoute ?
        Array.isArray(userRole) ?
            userRole.some(role => allowedRoute.roles.includes(role))
            : allowedRoute.roles.includes(userRole)
        : false;
    return hasAccess;

};

export const IsShowLicenseManagement = (Path, isShowLM) => {
    const LicenseManagementDashBoard = [
        "softwareLicenseManagement/Summary", 
        "softwareLicenseManagement/licenseManagement", 
        "/softwareLicenseManagement/licenseManagement/licenseconfigpage", 
        "configuration/cmdb/Devices/licenseconfigpage", 
        "softwareLicenseManagement/softwareGroup", 
        "softwareLicenseManagement/SoftwareList",
        "softwareLicenseManagement/ESXGroup",
        "configuration/cmdb/masterlist/Devices",
        "configuration/cmdb/:appName/DeviceConfig",
    ];

    return isShowLM === "" ? false : LicenseManagementDashBoard.includes(Path) && !isShowLM;
};

export const IsShowSirar = (Path, isShowSirar) => {
    const SirarDashBoard = [
        "Sirar",
        "Sirar/logApproverInsights",
        // "Sirar/logReviewerInsights"
    ];

    return isShowSirar === "" ? false : SirarDashBoard.includes(Path) && !isShowSirar;
};

export const navigationPanelConfig = [
    //firstChildNavigation
    { pagename: "home", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
    { pagename: "gnc", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
    { pagename: "configuration", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
    { pagename: "Business Info", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"]  },
    { pagename: "Server", roles: ["Operation"] },
    { pagename: "Account IDs", roles: ["Operation"] },
    { pagename: "Compliance", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
    { pagename: "Sirar", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Ciso", "Soc Lead", "Compliance Lead"] },
    { pagename: "Ciso Insights", roles: ["Ciso", "Operation", "Group Head", "Division Head", "Business Owner", "Compliance Lead"] },
    { pagename: "cloudCost", roles: ["Operation","Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "License Management", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
    { pagename: "softwareLicenseManagement", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
    { pagename: "CMDB", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Settings", roles: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner", "Finance Admin", "Finance Analyst"] },
    { pagename: "administration", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
    { pagename: "AuditLog", roles: ["Operation"] },

    //secondChildNavigation
    { pagename: "Business Info", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Role Allocation", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"]  },
    { pagename: "Users", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Devices", roles: ["Operation", "Application Owner"] },
    { pagename: "Cloud Accounts", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Server", roles: ["Operation"] },
    { pagename: "Account IDs", roles: ["Operation"] },
    { pagename: "Software Group", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
    { pagename: "Software List", roles: ["Operation","Division Head","License Group Owner","License Viewer"] },
    { pagename: "Application Configuration", roles: ["Operation"] },
    { pagename: "Cloud Cost", roles: ["Operation", "Finance Admin", "Finance Analyst", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Schedulers", roles: ["Operation","Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "Tools", roles: ["Operation", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
    { pagename: "PII Data Configuration", roles: ["Operation"] },
    { pagename: "Directory Configuration", roles: ["Operation"] },
    { pagename: "SmtpConfig", roles: ["Operation"] },
    { pagename: "LicenseConfig", roles: ["Operation"] },
    { pagename: "UserManagement", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner","License Group Owner","License Viewer"] },
    { pagename: "LoggingConfig", roles: ["Operation"] },
    {pagename: "RoleMapping", roles: ["Operation", "Ciso", "Compliance Lead", "Finance Admin", "Finance Analyst", "Soc Lead", "Group Head", "Department Head", "Division Head", "Application Owner", "Business Owner"] },
];
export const hasAccessToPage = (pagename, userRole, config) => {
    const pageAccess = config.find(page => page.pagename === pagename);
    const hasAccess = pageAccess ?
    Array.isArray(userRole) ? userRole.some(role => pageAccess.roles.includes(role))  :
    pageAccess.roles.includes(userRole) : true;
    return hasAccess;
};